import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/Practitioner.css"
import { HiUserAdd } from "react-icons/hi"
import { AiFillDelete, AiFillEdit, AiOutlineClose } from "react-icons/ai"
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { message, AutoComplete, Image } from 'antd';
import { FaDotCircle, FaShoppingCart, FaUser } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaCartFlatbed, FaRegCircleXmark } from 'react-icons/fa6';
import { ImUpload2 } from 'react-icons/im'
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from 'axios';
import { MdAttachEmail } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';


function Orderstates() {
    var sno1 = 1
    var sno2 = 1
    const [kit, setkit] = useState([])
    const [practitioner, setpractitioner] = useState([])
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")

    const [filter, setfilter] = useState("all")


    useEffect(() => {

        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



            var requestOptions = {
                method: 'GET',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
                .then(response => response.json())
                .then(result => setpractitioner(result))


            var myHeaders2 = new Headers();
            myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



            var requestOptions2 = {
                method: 'GET',
                headers: myHeaders2,

                redirect: 'follow'
            };

            fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions2)
                .then(response => response.json())
                .then(result => setkit(result))





        }


    }, [])

    var count = 0

    const [searchTerm, setSearchTerm] = useState('');
    var options = [];

    const dooption = () => {
        practitioner.map((value, index) => {

            // options = [
            //   { value: 'Burns Bay Road' },

            // ];
            // const newvalue = toString(value.kitid)
            options.push({ value: value.name })
            options.push({ value: value.email })


        })


    }



    const handleInputChange = (e) => {
        e.preventDefault()






        const intttt = (searchTerm)

        // Filter the Kit array based on the user's input
        const filteredSuggestions = practitioner.filter((item) =>

            item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



        );


        setpractitioner(filteredSuggestions)
        setsearchdone(true)

    };




    const [searchdone, setsearchdone] = useState(false)

    const clearsearch = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const hide = message.loading("Action in progress", 0)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,

            redirect: 'follow'
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
            .then(response => response.json())
            .then(result => setpractitioner(result))


        var myHeaders2 = new Headers();
        myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



        var requestOptions2 = {
            method: 'GET',
            headers: myHeaders2,

            redirect: 'follow'
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions2)
            .then(response => response.json())
            .then(result => setkit(result))


        setsearchdone(false)
        await setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
        }, 2000);
    }


    const [orders, setOrders] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false); // To show loading state

    const getOrders = async () => {
        // List of API URLs and their respective credentials
        const woocommerceSites = [
            {
                url: 'https://yourgutmap.co.uk/wp-json/wc/v3/',
                consumerKey: 'ck_3baf06e2c37cf5491ac07bc50bd423ac32d2e0f2',
                consumerSecret: 'cs_93c79b982026b949b8cb7c8a820ca3d4fcb460e8'
            },
            {
                url: 'https://ireland.yourgutmap.co.uk/wp-json/wc/v3/',
                consumerKey: 'ck_3baf06e2c37cf5491ac07bc50bd423ac32d2e0f2',
                consumerSecret: 'cs_93c79b982026b949b8cb7c8a820ca3d4fcb460e8'
            },
            {
                url: 'https://yourgutmap.com/wp-json/wc/v3/',
                consumerKey: 'ck_a67ddeb758c057f37432faf75948fc4c4e93f61a',
                consumerSecret: 'cs_dd2175ec24c3117de578bf1e042628368b9e21a4'
            },
            // {
            //     url: 'https://uk.yourgutmap.com/wp-json/wc/v3/',
            //     consumerKey: 'ck_a67ddeb758c057f37432faf75948fc4c4e93f61a',
            //     consumerSecret: 'cs_dd2175ec24c3117de578bf1e042628368b9e21a4'
            // }
        ];
    
        let allOrders = [];  // Store orders from all websites
        let totalPages = 2;  // Initial total pages (this will be updated dynamically)
    
        try {
            setLoading(true);
    
            // Loop through each site
            for (let site of woocommerceSites) {
                const { url, consumerKey, consumerSecret } = site;
                const encodedCredentials = btoa(`${consumerKey}:${consumerSecret}`);
                const headers = {
                    'Authorization': `Basic ${encodedCredentials}`,
                };
    
                let oders = [];
                // Fetch orders with pagination for the current site
                for (let i = 1; i <= totalPages; i++) {
                    const response = await axios.get(`${url}orders`, {
                        headers,
                        params: {
                            per_page: 100, // Number of orders per page
                            page: i,
                        },
                    });
    
                    const fetchedOrders = response.data;
                    oders.push(...fetchedOrders);
                    totalPages = parseInt(response.headers['x-wp-totalpages']);  // Update totalPages based on the response
                }
    
                // Filter out orders where line_items[0]?.name is "Seminar Ticket"
                oders = oders.filter(order => order.line_items[0]?.name !== "Seminar Ticket");
                allOrders.push(...oders);  // Combine orders from all websites
            }
    
            // Set the combined and filtered orders
            await setOrders(allOrders);
            setLoading(false);
    
        } catch (error) {
            console.error('Error fetching orders:', error);
            setLoading(false);
        }
    };
    
    


    useEffect(() => {
        getOrders();
    }, []);
    const [popup, setpopup] = useState(false)
    const [userinfo, setuserinfo] = useState([])



    const tootttalprice = () => {

        var totalpricec = 0
        orders.forEach((order) => {
            if (userinfo.email.toLowerCase() === order.billing.email.toLowerCase() || userinfo.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {

                totalpricec += parseInt(order.total);

            }
        });
        return totalpricec
    }
    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <Navbar />
                <div className='deshboardmain'>
                    <Sidemanu />
                    <div className='Practitionermainbody'>
                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><FaShoppingCart style={{ width: '50px', height: '50px' }} />Order States</h1>

                            {dooption()}

                            {searchdone === false && <>
                                <form onSubmit={handleInputChange}>
                                    <AutoComplete
                                        type="number"
                                        style={{ width: 200 }}
                                        options={options}
                                        placeholder="Search by Email/Name"
                                        filterOption={(inputValue, options) =>
                                            options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            //  console.log(kitss) 
                                        }
                                        onChange={(inputValue) => setSearchTerm(inputValue)}
                                    />
                                    <button>Search</button>
                                </form>

                            </>}
                            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
                            </>}
                        </div>

                        {popup === true && <>
                            <div onClick={() => {
                                setpopup(false)


                            }
                            } className='popupbg'></div>
                            <div className='popup'>



                                <div className='header' >
                                    <h2>Practitioner Orders History</h2>

                                </div>


                                <div className='kitdetailsmain' >






                                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.name}
                                    </h3></div>


                                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdAttachEmail style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.email} </h3>
                                    </div>

                                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaCartFlatbed style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Total Order</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {orders.filter(value => value.billing.email.toLowerCase() === userinfo.email.toLowerCase()).length} </h3>
                                    </div>

                                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <GiReceiveMoney style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Total Revenue</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}> &pound; {tootttalprice()} </h3>
                                    </div>





                                </div>
                                {orders.map((orderdetails) => {





                                    return (<>
                                        {(orderdetails.billing.email.toLowerCase() === userinfo.email.toLowerCase() || orderdetails.billing.first_name.toLowerCase() + ' ' + orderdetails.billing.last_name.toLowerCase() === userinfo.name.toLowerCase()) && <>


                                            <div style={{ marginTop: '50px' }}>
                                                <div className='header' >
                                                    <h2>Order #{orderdetails.id}</h2>
                                                    <h2>{orderdetails.status}</h2>
                                                </div>
                                                <div>Date : {orderdetails.date_created}</div>
                                                <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                                                    <thead className='tablephead'>
                                                        <tr>
                                                            <th >Product</th>
                                                            <th style={{ width: "100px" }}>Quantity	</th>
                                                            <th style={{ width: "100px" }}>Total</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderdetails.line_items.map((order, index) => {

                                                            return (
                                                                <tr key={index}>
                                                                    <td>{order.name}</td>
                                                                    <td>{order.quantity}</td>
                                                                    <td>&pound;{order.subtotal}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>}
                                    </>)
                                })}
                            </div>
                        </>}


                        <div className='header'>
                            <h1>Orders:   {loading ? (<>Loading...</>) : (<>{orders.length}</>)}</h1>
                            <select onChange={(e) => setfilter(e.target.value)} style={{ height: "35px", width: '255px', borderRadius: '5px', padding: '0 20px' }} value={filter}>
                                <option value={"all"}>All</option>
                                <option value={"0order"}>Have 0 Orders</option>
                                <option value={"1month"}>Never Ordered Since Last Month</option>

                                <option value={"3month"}>Never Ordered Since Last 3 Months</option>

                                <option value={"6month"}>Never Ordered Since Last 6 Months</option>

                            </select>


                        </div>


                        <div className='header' style={{ marginBottom: '20px' }}>
                            <h1></h1>
                            {loading ? (<><button className='button'>Loading...</button></>) : (<>

                            <button className='button' onClick={() => {

                                var filter2





                                let headers = ["Name", "Email", "Total Orders", "Revenue", "last Orders", "Last Order Date", "Last Order Items", "Last Order Revenue"];
                                let data = [];


                                practitioner.map((item) => {
                                    let total = 0;
                                    let totalcost = 0;
                                    let show = false;
                                    let userRow = [];
                                    let lastorder = []


                                    if (filter === "0order") {
                                        show = true;
                                        filter2 = " Never ordered yet "
                                        // Show all practitioners with their orders
                                        orders.forEach((order) => {
                                            if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase() ) {
                                                total += 1;

                                            }
                                        });

                                    } else if (filter === "all") {
                                        filter2 = " "
                                      
                                        // Show all practitioners with their orders
                                        orders.forEach((order) => {
                                            if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {
                                                total += 1;
                                                totalcost += parseInt(order.total);
                                                show = true; // Show practitioners with orders
                                                if (lastorder.length === 0) {
                                                    lastorder = order
                                                }


                                            }
                                        });

                                    }
                                    else if (filter === "6month") {

                                        filter2 = "Who Never ordered since 6 months "
                                        let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                        // Loop through all orders and check conditions
                                        orders.forEach((order) => {


                                            if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {



                                                total += 1;
                                                totalcost += parseInt(order.total);

                                                const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                                const today = new Date();
                                                const sixMonthsAgo = new Date();
                                                sixMonthsAgo.setMonth(today.getMonth() - 6);

                                                if (lastorder.length === 0) {
                                                    lastorder = order
                                                }
                                                // Check if order is within the last 6 months
                                                if (orderDate > sixMonthsAgo) {
                                                    hasRecentOrder = true; // Mark if there is any recent order
                                                }
                                            }
                                        });


                                        // Show if the practitioner has at least one order but none in the last 6 months
                                        if (total > 0 && !hasRecentOrder) {
                                            show = true;
                                        }
                                    }
                                    else if (filter === "3month") {
                                        filter2 = "Who  Never ordered since 6 months "

                                        let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                        // Loop through all orders and check conditions
                                        orders.forEach((order) => {


                                            if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {

                                                if (lastorder.length === 0) {
                                                    lastorder = order
                                                }

                                                total += 1;
                                                totalcost += parseInt(order.total);

                                                const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                                const today = new Date();
                                                const sixMonthsAgo = new Date();
                                                sixMonthsAgo.setMonth(today.getMonth() - 3);

                                                // Check if order is within the last 6 months
                                                if (orderDate > sixMonthsAgo) {
                                                    hasRecentOrder = true; // Mark if there is any recent order
                                                }
                                            }
                                        });


                                        // Show if the practitioner has at least one order but none in the last 6 months
                                        if (total > 0 && !hasRecentOrder) {
                                            show = true;
                                        }
                                    }
                                    else if (filter === "1month") {
                                        filter2 = "Who  Never ordered since Last month "

                                        let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                        // Loop through all orders and check conditions
                                        orders.forEach((order) => {


                                            if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {


                                                if (lastorder.length === 0) {
                                                    lastorder = order
                                                }
                                                total += 1;
                                                totalcost += parseInt(order.total);

                                                const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                                const today = new Date();
                                                const sixMonthsAgo = new Date();
                                                sixMonthsAgo.setMonth(today.getMonth() - 1);

                                                // Check if order is within the last 6 months
                                                if (orderDate > sixMonthsAgo) {
                                                    hasRecentOrder = true; // Mark if there is any recent order
                                                }
                                            }
                                        });


                                        // Show if the practitioner has at least one order but none in the last 6 months
                                        if (total > 0 && !hasRecentOrder) {
                                            show = true;
                                        }
                                    }


                                    if (show && filter !== "0order") {

                                        const itemsss = lastorder.line_items.map((order, index) => ` ${order.name} (${order.quantity})`).join("    ") // Joining with ", " to separate items

                                        userRow.push(
                                            item.name,
                                            item.email,
                                            total,
                                            totalcost,
                                            lastorder.id,
                                            lastorder.date_created,
                                            itemsss,
                                            lastorder.total
                                        );


                                    } if (show && filter === "0order" && total === 0) {

                                        headers = ["Name", "Email", "Total Orders"];

                                        userRow.push(
                                            item.name,
                                            item.email,
                                            total,

                                        );


                                    }






                                    if (userRow.length > 0) {
                                        data.push(userRow);
                                    }
                                    //  else if (filter === "0order") {
                                    //     // Show only practitioners with 0 orders
                                    //     orders.forEach((order) => {
                                    //         if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {
                                    //             total += 1;
                                    //             totalcost += parseInt(order.total);
                                    //         }
                                    //     });
                                    //     // Show practitioners who have no orders
                                    //     show = total === 0;
                                    // }
                                    //  else if (filter === "6month") {

                                    //     let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                    //     // Loop through all orders and check conditions
                                    //     orders.forEach((order) => {


                                    //         if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {



                                    //             total += 1;
                                    //             totalcost += parseInt(order.total);

                                    //             const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                    //             const today = new Date();
                                    //             const sixMonthsAgo = new Date();
                                    //             sixMonthsAgo.setMonth(today.getMonth() - 6);

                                    //             // Check if order is within the last 6 months
                                    //             if (orderDate > sixMonthsAgo) {
                                    //                 hasRecentOrder = true; // Mark if there is any recent order
                                    //             }
                                    //         }
                                    //     });


                                    //     // Show if the practitioner has at least one order but none in the last 6 months
                                    //     if (total > 0 && !hasRecentOrder) {
                                    //         show = true;
                                    //     }
                                    // }
                                    //  else if (filter === "3month") {

                                    //     let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                    //     // Loop through all orders and check conditions
                                    //     orders.forEach((order) => {


                                    //         if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {



                                    //             total += 1;
                                    //             totalcost += parseInt(order.total);

                                    //             const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                    //             const today = new Date();
                                    //             const sixMonthsAgo = new Date();
                                    //             sixMonthsAgo.setMonth(today.getMonth() - 3);

                                    //             // Check if order is within the last 6 months
                                    //             if (orderDate > sixMonthsAgo) {
                                    //                 hasRecentOrder = true; // Mark if there is any recent order
                                    //             }
                                    //         }
                                    //     });


                                    //     // Show if the practitioner has at least one order but none in the last 6 months
                                    //     if (total > 0 && !hasRecentOrder) {
                                    //         show = true;
                                    //     }
                                    // }
                                    //  else if (filter === "1month") {

                                    //     let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                    //     // Loop through all orders and check conditions
                                    //     orders.forEach((order) => {


                                    //         if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {



                                    //             total += 1;
                                    //             totalcost += parseInt(order.total);

                                    //             const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                    //             const today = new Date();
                                    //             const sixMonthsAgo = new Date();
                                    //             sixMonthsAgo.setMonth(today.getMonth() - 1);

                                    //             // Check if order is within the last 6 months
                                    //             if (orderDate > sixMonthsAgo) {
                                    //                 hasRecentOrder = true; // Mark if there is any recent order
                                    //             }
                                    //         }
                                    //     });


                                    //     // Show if the practitioner has at least one order but none in the last 6 months
                                    //     if (total > 0 && !hasRecentOrder) {
                                    //         show = true;
                                    //     }
                                    // }


                                    // Iterate over question.Fields and push each value.title to headers
                                    // question.Fields.map((value) => {
                                    //     headers.push(value.title);
                                    // });

                                    // // Add "Join at" to the headers
                                    // headers.push("Join at");

                                    // // Iterate over each user and create a row of data for each
                                    // question.user.map((user) => {
                                    //     let userRow = [];

                                    //     // Loop through headers and push the corresponding user data
                                    //     headers.forEach((header) => {
                                    //         if (header === "Join at") {
                                    //             // Add the "Join at" data for the user
                                    //             userRow.push(user.joinat || ""); // If `joinat` is undefined, push empty string
                                    //         } else {
                                    //             // Add the corresponding field data for the user
                                    //             userRow.push(user[header] || ""); // If the field is undefined, push empty string
                                    //         }
                                    //     });

                                    //     // Push the user row to the data array
                                    //     data.push(userRow);
                                    // });


                                })
                                const csvData = [
                                    headers, // Header row
                                    ...data    // Single data row
                                ];


                                const csvRows = csvData.map(row => row.join(",")).join("\n");


                                const blob = new Blob([csvRows], { type: 'text/csv' });


                                const link = document.createElement('a');


                                link.href = URL.createObjectURL(blob);


                                link.download = `Practitioner List ${filter2}.csv`;


                                document.body.appendChild(link);
                                link.click();


                                document.body.removeChild(link);


                            }}>Download CSV File</button>

                            </>)}



                        </div>




                        {practitioner.length !== 0 && <>
                            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                                <thead className='tablephead'>
                                    <tr>


                                        <th>S NO.</th>
                                        <th>Name / Email</th>

                                        <th>Total Order (Value)</th>


                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {practitioner.map((item, index) => {
                                        let total = 0;
                                        let totalcost = 0;
                                        let show = false;


                                        if (filter === "all") {
                                            // Show all practitioners with their orders
                                            orders.forEach((order) => {
                                                if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {
                                                    total += 1;
                                                    totalcost += parseInt(order.total);
                                                    show = true; // Show practitioners with orders
                                                }
                                            });
                                        } else if (filter === "0order") {
                                            // Show only practitioners with 0 orders
                                            orders.forEach((order) => {
                                                if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {
                                                    total += 1;
                                                    totalcost += parseInt(order.total);
                                                }
                                            });
                                            // Show practitioners who have no orders
                                            show = total === 0;
                                        } else if (filter === "6month") {

                                            let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                            // Loop through all orders and check conditions
                                            orders.forEach((order) => {


                                                if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {



                                                    total += 1;
                                                    totalcost += parseInt(order.total);

                                                    const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                                    const today = new Date();
                                                    const sixMonthsAgo = new Date();
                                                    sixMonthsAgo.setMonth(today.getMonth() - 6);

                                                    // Check if order is within the last 6 months
                                                    if (orderDate > sixMonthsAgo) {
                                                        hasRecentOrder = true; // Mark if there is any recent order
                                                    }
                                                }
                                            });


                                            // Show if the practitioner has at least one order but none in the last 6 months
                                            if (total > 0 && !hasRecentOrder) {
                                                show = true;
                                            }
                                        } else if (filter === "3month") {

                                            let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                            // Loop through all orders and check conditions
                                            orders.forEach((order) => {


                                                if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {



                                                    total += 1;
                                                    totalcost += parseInt(order.total);

                                                    const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                                    const today = new Date();
                                                    const sixMonthsAgo = new Date();
                                                    sixMonthsAgo.setMonth(today.getMonth() - 3);

                                                    // Check if order is within the last 6 months
                                                    if (orderDate > sixMonthsAgo) {
                                                        hasRecentOrder = true; // Mark if there is any recent order
                                                    }
                                                }
                                            });


                                            // Show if the practitioner has at least one order but none in the last 6 months
                                            if (total > 0 && !hasRecentOrder) {
                                                show = true;
                                            }
                                        } else if (filter === "1month") {

                                            let hasRecentOrder = false; // To track if there is any recent order within 6 months

                                            // Loop through all orders and check conditions
                                            orders.forEach((order) => {


                                                if (item.email.toLowerCase() === order.billing.email.toLowerCase() || item.name.toLowerCase() === order.billing.first_name.toLowerCase() + ' ' + order.billing.last_name.toLowerCase()) {



                                                    total += 1;
                                                    totalcost += parseInt(order.total);

                                                    const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                                                    const today = new Date();
                                                    const sixMonthsAgo = new Date();
                                                    sixMonthsAgo.setMonth(today.getMonth() - 1);

                                                    // Check if order is within the last 6 months
                                                    if (orderDate > sixMonthsAgo) {
                                                        hasRecentOrder = true; // Mark if there is any recent order
                                                    }
                                                }
                                            });


                                            // Show if the practitioner has at least one order but none in the last 6 months
                                            if (total > 0 && !hasRecentOrder) {
                                                show = true;
                                            }
                                        }

                                        return (
                                            <>
                                                {show && (
                                                    <tr>
                                                        <td>{sno2++}</td>

                                                        <td style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                                            <div>{item.name}</div>
                                                            <div>{item.email}</div>
                                                        </td>
                                                        <td>{total} (&pound;{totalcost})</td>
                                                        <td>
                                                            <button className='button' onClick={async () => {
                                                                setpopup(true);
                                                                setuserinfo(item);
                                                            }}>
                                                                Detail
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        );
                                    })}








                                </tbody>
                            </table>


                        </>}




                        {practitioner.length === 0 && <>

                            <img alt='' src='/empty.gif' width={"40%"} />
                        </>}




                    </div>
                </div>
            </>}
        </div>
    )
}

export default Orderstates
