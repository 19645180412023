import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Checkbox, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../css/sendemail.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaFileArrowDown } from 'react-icons/fa6';
import { ImCross } from 'react-icons/im';
import ReactQuill from 'react-quill';
import Papa from 'papaparse';
import { ImUpload2, ImUserTie } from 'react-icons/im'

function Sendemail() {
    const fileInputRef = useRef(null);

    var sno1 = 1
    var sno2 = 1

    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const loginemail = Cookies.get("email")


    const [practitioner, setpractitioner] = useState([])
    const [tosendemail, settosendemail] = useState([])
    const [nottosend, setnottosend] = useState([])

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean'] // removes formatting
        ],
    };


    const getprac = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



        var requestOptions = {
            method: 'GET',
            headers: myHeaders,

            redirect: 'follow'
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
            .then(response => response.json())
            .then(result => setpractitioner(result))

    }

    useEffect(() => {
        getprac()
    }, [])



    const procces = () => {
        settosendemail(tosendemail.filter(item => !nottosend.includes(item)));
    }


    useEffect(() => {
        procces()
    }, [practitioner, nottosend])



    const gettosend = () => {
        let array = []; // Initialize the array

        practitioner.forEach((value) => {
            array.push(value.email); // Push email values into the array
        });

        settosendemail(array); // Update the state with the resulting array
    };


    useEffect(() => {
        gettosend()
    }, [practitioner])









    const [popup4, setpopup4] = useState(false)

    const [csvFile, setCSVFile] = useState(null);
    const [csvData, setCsvData] = useState([]);




    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        // console.log(event.target.files)
        // console.log(csvFile)
        setCSVFile(file)

        await Papa.parse(file, {
            complete: (result) => {

                setCsvData(result.data);
                // setCsvData(result.data[0])

            },
            header: true,
            skipEmptyLines: true
        });



    };


    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && droppedFile.type === 'text/csv') {
            setCSVFile(droppedFile);
            Papa.parse(droppedFile, {

                complete: (result) => {

                    setCsvData(result.data);
                    // setCsvData(result.data[0])

                },
                header: true,
                skipEmptyLines: true
            });


        } else {
            alert('Please drop a valid CSV file.');
        }
    };




    const datauploaded = async () => {
        if (!csvFile) {
            message.error("Please upload a file first");
            return;
        }
    
        const hide = message.loading("Action in progress", 0);
    
        try {
            // Extract emails from csvData
            const array = csvData.map((value) => value.Email);
            console.log("Extracted Emails:", array);
    
            // Update nottosend by merging with previous values and removing duplicates
            setnottosend((prev) => {
                const merged = [...new Set([...prev, ...array])];
                return merged;
            });
    
            console.log("Updated nottosend:", nottosend);
    
            // Process email filtering
            settosendemail((prev) => prev.filter((item) => !nottosend.includes(item)));
    
            // Placeholder for multiple process calls
            await Promise.all([procces(), procces(), procces()]);
    
            // End loading and notify user
            hide();
            message.success("Action completed successfully");
            setpopup4(false);
        } catch (error) {
            console.error("An error occurred:", error);
            hide();
            message.error("An error occurred during the process");
        }
    };
    




    const handleClearInput = () => {
        // setFiles([]); // Clear the files state
        fileInputRef.current.value = ''; // Clear the file input value
        setCSVFile(null)
    };

    const [subject, setsubject] = useState("")
    const [content, setcontent] = useState("")

    const sendemailsss = async () => {
        const hide = message.loading("Action in progress", 0);

        if (subject === "" && content === "" && tosendemail.length === 0) {
            hide();
            message.error("unable to send emails")
        } else {
            if (subject === "") {
                hide();
                message.error("Please enter email Subject")
            } else if (content === "") {
                hide();
                message.error("Please enter email Content")
            } else {





                // tosendemail.map(async (value) => {

                    const urlencoded = new URLSearchParams();
                    urlencoded.append("content", content);
                    urlencoded.append("subject", subject);
                    urlencoded.append("email", "aliahmed.samoo.1@gmail.com");

                    const requestOptions = {
                        method: "POST",
                        body: urlencoded,
                        redirect: "follow"
                    };

                    await fetch("http://localhost:1337/sendmassemails", requestOptions)
                        .then((response) => response.text())
                        .then((result) => console.log(result))
                        .catch((error) => console.error(error));





                // })

                setsubject("")
                setcontent("")

                hide(); // Stop the loading message
                message.success("Action completed successfully");
            }


        }
    }


    return (
        <div>
            {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && <>



                <Navbar />

                <div className='deshboardmain'>
                    <Sidemanu />

                    <div className='mainbody'>

                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><MdEmail style={{ width: '50px', height: '50px' }} />Send Emails</h1>


                        </div>


                        <div className='headeremails'>
                            <div className='tosend'>
                                <h5>TO Send  <h2>{tosendemail.length}</h2></h5>
                                <div className='alltosendemails'>
                                    {tosendemail.map((value) => (<>
                                        <p>{value} <ImCross onClick={() => {

                                            setnottosend(prev => [...prev, value]);

                                        }} style={{ color: 'red', cursor: 'pointer' }} /> </p>
                                    </>))}

                                </div>
                            </div>
                            <div className='tosend'>
                                <h5>NOT TO Send  <h2>{nottosend.length}</h2></h5>
                                <div className='alltosendemails'>
                                    {nottosend.map((value, idx) => (
                                        <p key={idx}>
                                            {value}
                                            <ImCross
                                                onClick={() => {
                                                    settosendemail((prev) => [...prev, value]); // Add the value to "tosendemail"
                                                    setnottosend((prevArray) => prevArray.filter((_, index) => index !== idx)); // Remove the correct index
                                                }}
                                                style={{ color: 'red', cursor: 'pointer' }}
                                            />
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>


                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                            <div className='addbutton' style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}><h1></h1><button onClick={() => setpopup4(true)} className='button' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><ImUpload2 style={{ width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />Upload CSV File</button></div>
                        </>}



                        {popup4 === true && <>
                            <div onClick={() => {
                                setpopup4(false)
                                setCSVFile(null)
                            }
                            } className='popupbg'></div>
                            <div className='popup'>


                                <p style={{ textAlign: 'left' }}><strong>Note:</strong> The uploaded CSV file must contain a proper header with the following fields: <strong>Emails</strong>. Ensure that there are no leading or trailing spaces in the header fields. The header row should be the first row in the file. Additionally.</p>
                                <div className="file-drop-zone"


                                    onDrop={handleFileDrop}
                                    onDragOver={(e) => e.preventDefault()}

                                >

                                    <label className="custom-file-upload" >

                                        <div className='clickablediv'>


                                            <ImUpload2 style={{ width: '100px', height: '100px' }} />
                                            <h4>Click or drag file to this area to upload</h4>


                                        </div>
                                        <input
                                            multiple="off"
                                            ref={fileInputRef}
                                            className='inputbuttontouploadfile'
                                            type="file"
                                            accept=".csv"
                                            onChange={handleFileUpload}
                                        />

                                    </label>

                                </div>

                                <div className='filename'> {csvFile && (<> <p>Selected CSV File: {csvFile.name}</p><AiFillDelete onClick={handleClearInput} className='hovar' style={{ width: '30px', height: '30px', color: 'red' }} /></>)}</div>





                                <div className='bownpopupbutton'>
                                    <button onClick={() => {
                                        setpopup4(false)
                                        setCSVFile(null)
                                    }
                                    } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                                    <button onClick={datauploaded} style={{ backgroundColor: '#4180b7' }}>Upload</button>




                                </div>

                            </div>
                        </>}


                        <div className='emailbody'>
                           

                            <div className='contantdiv'>
                                <h3>Email Subject:</h3>
                                <input className='formalinput' placeholder='Subject' value={subject} onChange={(e) => setsubject(e.target.value)} />

                            </div>


                            <div >

                                <div className='contantdiv'>



                                    <h3>Email Content:</h3>
                                    <div  >
                                        <ReactQuill
                                            require="true"
                                            value={content}
                                            onChange={(value) => {
                                                setcontent(value)
                                            }}
                                            modules={modules}
                                            placeholder="Add a description"
                                        // theme="snow" // snow is the default theme
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='contantdiv'>
                                <button onClick={sendemailsss} className='button'>SEND EMAIL</button>
                            </div>

                        </div>

                    </div>

                </div>



            </>}
        </div>
    )
}

export default Sendemail
