import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/deshboard.css"
import { BiSolidAddToQueue } from "react-icons/bi"
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import { MdAssignmentReturn, MdAttachEmail, MdEditSquare, MdOutlinePayment, MdOutlinePayments, MdOutlinePendingActions } from "react-icons/md"
import { loadStripe } from '@stripe/stripe-js';
import { AiOutlineClose } from "react-icons/ai"
import { IoIosArrowDropdown } from "react-icons/io";
import Cookies from 'js-cookie';
import { AutoComplete, Button, Dropdown, Input, message } from 'antd';
import Item from 'antd/es/list/Item';
import { FaKitMedical } from 'react-icons/fa6';
import { GiFirstAidKit } from 'react-icons/gi';
import { FaUser } from 'react-icons/fa';
import { TbGitBranchDeleted, TbReportMoney } from "react-icons/tb";
import { SiSemaphoreci } from "react-icons/si";


function Candidakit() {



  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const token2 = Cookies.get("Token2")

  const [practitioner2, setpractitioner2] = useState([])
  const [popup, setpopup] = useState(false)
  const [popup2, setpopup2] = useState("addkit")
  const [addkit2, setaddkit2] = useState("")
  const [addkit2p, setaddkit2p] = useState("")
  const [addkit2p2, setaddkit2p2] = useState("")
  const [addkit, setaddkit] = useState([])
  const [kit, setkit] = useState([])
  const [kitid, setkitid] = useState("")
  const [kitiddb, setkitiddb] = useState("")
  const [kittype, setkittype] = useState("")

  const [numberofkits, setnumberofkits] = useState()
  const [kitdetails, setkitdetails] = useState([])

  const [practitioner, setpractitioner] = useState([])
  const [practitionerid, setpractitionerid] = useState("")
  const [ackKit, setackKit] = useState([])


  const Addkit = async (e) => {
    await e.preventDefault()


    const Kittt = addkit2.replace(/\D/g, '');

    const kitiddd = parseInt(Kittt)

    const alphabets = addkit2.replace(/[^a-zA-Z]/g, '');


    await setaddkit([...addkit, { Kit: kitiddd, Kitprice: addkit2p, Kittype: "cp", prefix: alphabets }]);
    setaddkit2("")
    setaddkit2p("")
    setkittype("")


  }

  const Addkit2 = async (e) => {
    e.preventDefault();


    const existingKitIds = kit.map((k) => k.kitid); // Assuming kit is an array of objects with kitid

    const generateUniqueKitIds = (count, existingIds) => {
      const uniqueIds = new Set();
      while (uniqueIds.size < count) {
        const randomId = Math.floor(10000000 + Math.random() * 90000000); // Generates an 8-digit number
        if (!existingIds.includes(randomId)) {
          uniqueIds.add(randomId);
        }
      }
      return Array.from(uniqueIds);
    };

    const randomKitIds = generateUniqueKitIds(numberofkits, existingKitIds);

    // Generate the items to add to addkit with each random ID
    const newKits = randomKitIds.map((kitId) => ({
      Kit: kitId,
      Kitprice: addkit2p2,
      Kittype: "cp",
      prefix: "CP",
    }));

    // Update the addkit state with the new kits
    await setaddkit([...addkit, ...newKits]);

    // Clear input values if needed
    setaddkit2("");
    setaddkit2p2("");
    setkittype("");
    setnumberofkits("");

  };


  const RemoveItemByIndex = (index) => {
    // Create a new array without the item at the specified index
    const updatedAddkit = addkit.filter((_, i) => i !== index);
    setaddkit(updatedAddkit);
  };






  const sendnewkittobackend = async () => {
    const hide = message.loading("Action in progress", 0)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json"); // Change the content type to JSON

    // Create an array


    // Convert the array to a JSON string
    var jsonData = JSON.stringify(addkit);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: jsonData, // Send the JSON data
      redirect: 'follow'
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addkit", requestOptions)
      .then(response => response.json())
      .then(result => setkit(result),

        setpopup(false),
        setaddkit([]),
        setaddkit2(""),


      )
      .catch(error => console.log('error', error));


    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);


  }



  const assignkittopra = async () => {
    const hide = message.loading("Action in progress", 0)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("kitID", kitiddb);
    urlencoded.append("userid", practitionerid);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/assignkittopra", requestOptions)
      .then(response => response.json())
      .then(result => setkit(result),

        setpopup(false),
        setaddkit([]),
        setaddkit2(""),
      )
      .catch(error => console.log('error', error));

    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  }


  const assignkittopra2 = async () => {



    const hide = message.loading("Action in progress", 0)


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("kits", JSON.stringify(addkit));
    urlencoded.append("userid", practitionerid);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/assignkittopraandadd", requestOptions)
      .then(response => response.json())
      .then(result => setkit(result),

        setpopup(false),
        setaddkit([]),
        setaddkit2(""),

      )
      .catch(error => console.log('error', error));





    var requestOptions2 = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };



    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions2)
      .then(response => response.json())
      .then(result => setkit(result))


    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  }



  useEffect(() => {

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53"   || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => {
          setpractitioner(result)
          setpractitioner2(result)
        })


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallemaidata", requestOptions2)
        .then(response => response.json())
        .then(result => setackKit(result))


    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }






  }, [])


  // const Kit = [
  //   { name: 'ali', age: 25 },
  //   { name: 'ahmed', age: 15 },
  //   { name: 'ahmed', age: 15 },
  //   { name: 'samoo', age: 22 },
  // ];

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (e) => {
    e.preventDefault()





    const Kittt = searchTerm.replace(/\D/g, '');

    const intttt = parseInt(Kittt)

    // Filter the Kit array based on the user's input
    const filteredSuggestions = kit.filter((item) =>
      item.kitid === intttt
    );

    // setSuggestions(filteredSuggestions);

    setkit(filteredSuggestions)
    setsearchdone(true)

  };

  var options = [];

  const dooption = () => {
    kit.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      if (value.Kittype === "cp") {
        options.push({ value: `${value.prefix}${value.kitid}` })
      }

    })


  }





  const [searchdone, setsearchdone] = useState(false)



  const clearsearch = () => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => {
          setpractitioner(result)
          setpractitioner2(result)
        })





    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }

    setsearchdone(false)

  }






































  var sno1 = 1
  var sno2 = 1
  var sno3 = 1





  const [searchTerm2, setSearchTerm2] = useState('');
  var options2 = [];

  const dooption2 = () => {
    practitioner.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      options2.push({ value: value.name })
      options2.push({ value: value.email })


    })


  }



  const handleInputChange2 = (e) => {
    e.preventDefault()






    const intttt = (searchTerm2)

    // Filter the Kit array based on the user's input
    const filteredSuggestions = practitioner.filter((item) =>

      item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



    );


    setpractitioner2(filteredSuggestions);
    console.log(filteredSuggestions)
    setsearchdone2(true)

  };




  const [searchdone2, setsearchdone2] = useState(false)

  const clearsearch2 = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
      .then(response => response.json())
      .then(result => setpractitioner(result))


    var myHeaders2 = new Headers();
    myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions2 = {
      method: 'GET',
      headers: myHeaders2,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions2)
      .then(response => response.json())
      .then(result => setkit(result))


    setsearchdone2(false)

  }





  const findpracemail = () => {

    var emailll
    practitioner.map((value) => {

      if (value._id === kitdetails.assignedto) {
        emailll = value.email

      }


    })
    if (token !== "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      emailll = loginemail

    }

    return emailll



  }

  const findpracname = () => {

    var nameee
    practitioner.map((value) => {

      if (value._id === kitdetails.assignedto) {
        nameee = value.name

      }


    })

    if (token !== "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      nameee = loginname

    }
    return nameee



  }

  const Kitsstaus = () => {


    var kitinfo = []
    var match = false


    ackKit.map((item, index) => {



      if (item.KitId === kitdetails.kitid) {

        match = true
      }




    })












    var statussss
    if (!kitdetails.assignedto) {
      statussss = "Kit Created"
    } else if (kitdetails.otherresults !== "nil") {
      statussss = "Results Published"
    } else if (match && kitdetails.otherresults === "nil") {
      statussss = "Kit received, awaiting results."
    }
    else if (!match && kitdetails.otherresults === "nil") {

      statussss = "Kit Not received in lab yet."
    }



    return statussss
  }

  const kitdetailsKitprice = () => {
    var paymenttt
    if (kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf") {
      paymenttt = kitdetails.Kitprice2
    } else {
      paymenttt = kitdetails.Kitprice2
    }

    return paymenttt
  }

  const kitdetailsKitpricestatus = () => {
    var paymenttt
    if (kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf") {
      paymenttt = "Payment Completed"
    } else {
      paymenttt = "Payment Pending"
    }

    return paymenttt
  }
















  const items = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {


          const stripe = await loadStripe("pk_live_51MsenqJSzdsymN5jGOTIP3q4qBmD4PDra9chWFQYDC6RCchx2jLlIgdDRrUnhI24QhZeNeAqEo9tc6l3oiR4SWc3000yjqG8qW");

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("KitID", kitdetails._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };


          const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/create-checkout-session", requestOptions)

          const session = await response.json();

          const result = stripe.redirectToCheckout({
            sessionId: session.id
          });

          if (result.error) {
            console.log(result.error);
          }




        }}>
          Pay The Ammount Of Kit
        </a>
      ),
      icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async (e) => {
          e.preventDefault()

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


          var urlencoded2 = new URLSearchParams();
          urlencoded2.append("id", kitdetails._id);

          var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded2,
            redirect: 'follow'
          };


          await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/kitpricepaymentdoneover", requestOptions2)
            .then(response => response.json())
            .then(result => setkit(result))
            .catch(error => console.log('error', error));

          await setpopup(false)
          await setaddkit([])
          await setaddkit2("")
          await setpopup2("addkit")

        }}>


          Mark as paid

        </a>


      ),


      icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" >


          Payment is Due

        </a>


      ),

      disabled: true,
      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

  ];


  const items1 = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {

          const hide = message.loading("Action in progress", 0)
          const stripe = await loadStripe("pk_live_51MsenqJSzdsymN5jGOTIP3q4qBmD4PDra9chWFQYDC6RCchx2jLlIgdDRrUnhI24QhZeNeAqEo9tc6l3oiR4SWc3000yjqG8qW");

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("KitID", kitdetails._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };


          const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/create-checkout-session", requestOptions)

          const session = await response.json();

          const result = stripe.redirectToCheckout({
            sessionId: session.id
          });

          if (result.error) {
            console.log(result.error);
          }



          await setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);
        }}>
          Pay The Ammount Of Kit
        </a>
      ),
      disabled: kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf",
      icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },


  ];

  const items2 = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer"
        >
          Pay The Ammount Of Kit
        </a>
      ),
      disabled: true,
      icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async (e) => {
          e.preventDefault()

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


          var urlencoded2 = new URLSearchParams();
          urlencoded2.append("id", kitdetails._id);

          var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded2,
            redirect: 'follow'
          };


          await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/kitpricepaymentdoneover", requestOptions2)
            .then(response => response.json())
            .then(result => setkit(result))
            .catch(error => console.log('error', error));

          await setpopup(false)
          await setaddkit([])
          await setaddkit2("")
          await setpopup2("addkit")

        }}>


          Mark as paid

        </a>


      ),

      disabled: true,
      icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async (e) => {

          e.preventDefault()
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", kitdetails._id);


          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };

          const resp = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/ackemailpaymentcp", requestOptions)
            .then(response => response.json())
            .then(result => setkit(result))
            .catch(error => console.log('error', error));

          await setpopup(false)
          await setaddkit([])
          await setaddkit2("")
          await setpopup2("addkit")

        }}>


          Send Payment Acknowledgment

        </a>


      ),

      disabled: kitdetails.ackpayment,
      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

  ];



  const itemsstatus1 = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault()


            ackKit.map((item, index) => {



              if (item.KitId === kitdetails.kitid) {




                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("kitID", item.KitId);
                urlencoded.append("patientName", item.Name);
                urlencoded.append("_id", item._id);

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow'
                };

                const resp = fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/ackemailcp", requestOptions)
                  .then(response => response.json())
                  .then(result => setkit(result))
                  .catch(error => console.log('error', error));

                setpopup(false)
                setaddkit([])
                setaddkit2("")
                setpopup2("addkit")

              }




            })

          }}>
          Send Kit Received Acknowledgment
        </a>
      ),
      disabled: (kitdetails.ack),
      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer"


          onClick={async (e) => {
            e.preventDefault()


            ackKit.map((item, index) => {



              if (item.KitId === kitdetails.kitid) {




                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("kitID", item.KitId);
                urlencoded.append("patientName", item.Name);
                urlencoded.append("_id", item._id);

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow'
                };

                const resp = fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/ackemailandmaypentcp", requestOptions)
                  .then(response => response.json())
                  .then(result => setkit(result))
                  .catch(error => console.log('error', error));

                setpopup(false)
                setaddkit([])
                setaddkit2("")
                setpopup2("addkit")

              }




            })

          }}
        >


          Send Kit Received Acknowledgment with Payment information

        </a>


      ),
      disabled: (kitdetails.ack && kitdetails.ackpayment),

      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" >


          Results are Pending

        </a>


      ),

      disabled: true,
      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

  ];

  const itemsstatus2 = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer"
          onClick={async (e) => {
            e.preventDefault()


            ackKit.map((item, index) => {



              if (item.KitId === kitdetails.kitid) {




                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("kitID", item.KitId);
                urlencoded.append("patientName", item.Name);
                urlencoded.append("_id", item._id);

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow'
                };

                const resp = fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/ackemailcp", requestOptions)
                  .then(response => response.json())
                  .then(result => setkit(result))
                  .catch(error => console.log('error', error));

                setpopup(false)
                setaddkit([])
                setaddkit2("")
                setpopup2("addkit")

              }




            })

          }}>
          Send Kit Received Acknowledgment
        </a>
      ),
      disabled: (kitdetails.ack),
      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer"


          onClick={async (e) => {
            e.preventDefault()


            ackKit.map((item, index) => {



              if (item.KitId === kitdetails.kitid) {




                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("kitID", item.KitId);
                urlencoded.append("patientName", item.Name);
                urlencoded.append("_id", item._id);

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow'
                };

                const resp = fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/ackemailandmaypentcp", requestOptions)
                  .then(response => response.json())
                  .then(result => setkit(result))
                  .catch(error => console.log('error', error));

                setpopup(false)
                setaddkit([])
                setaddkit2("")
                setpopup2("addkit")

              }




            })

          }}
        >


          Send Kit Received Acknowledgment with Payment information

        </a>


      ),
      disabled: (kitdetails.ack && kitdetails.ackpayment),

      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {


          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", kitdetails._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };






          const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresultsothers", requestOptions);
          const result = await response.json();
          await setkit(result);




          setpopup(false)

          setpopup2("addkit")





        }}>
          Send Results To Practitioner
        </a>


      ),

      disabled: kitdetails.ackresult,
      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

  ];






  return (<>
    {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && <>


      <Navbar />
      <div className='deshboardmain'>
        <Sidemanu />
        <div className='adminkitmainbody'>
          <div className='header'>
            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><FaKitMedical style={{ width: '50px', height: '50px' }} />Candida Profile kits</h1>
            {dooption()}
            {searchdone === false && <>
              <form onSubmit={handleInputChange}>
                {/* <input required

                type="text"
                value={searchTerm}
                onChange={handleInputChange}

                placeholder='Search by Kit ID' /> */}

                <AutoComplete
                  type="number"
                  style={{ width: 200 }}
                  options={options}
                  placeholder="Search by Kit ID"
                  filterOption={(inputValue, options) =>
                    options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    //  console.log(kitss) 
                  }
                  onChange={(inputValue) => setSearchTerm(inputValue)}
                />
                <button>Search</button>
              </form>
            </>}
            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
            </>}
          </div>




          {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>    <div className='addbutton'><button onClick={() => {
            setpopup(true)
            setpopup2("addkit")
          }}><BiSolidAddToQueue style={{ width: '20px', height: '20px' }} />Add Kit</button></div></>}
          {popup === true && <>
            <div onClick={() => {
              setpopup(false)
              setaddkit([])
              setaddkit2("")
              setpopup2("addkit")
            }
            } className='popupbg'></div>
            <div className='popup'>

              {popup2 === "addkit" && <>

                <form onSubmit={Addkit}>
                  {/* <select style={{width:'70px'}} onChange={(e)=>setprefix(e.target.value)}>
                      <option value={"PGM"} selected>PGM</option>
                      <option value={"GMEG"}>GMEG</option>
                    </select> */}
                  <input onChange={(e) => setaddkit2(e.target.value)} value={addkit2} required placeholder='Enter the Kit ID' />

                  <input pattern="(\d).{1,10000}" onChange={(e) => setaddkit2p(e.target.value)} value={addkit2p} required placeholder='Enter the Kit Price' />

                  <button >ADD</button>
                </form>

                <form onSubmit={Addkit2}>

                  <input
                    type="number"
                    min="1"
                    max="100"
                    onChange={(e) => setnumberofkits(e.target.value)}
                    value={numberofkits}
                    required
                    placeholder="HOW MANY KITS YOU WANT TO GENERATE"
                  /> <input pattern="(\d).{1,10000}" onChange={(e) => setaddkit2p2(e.target.value)} value={addkit2p2} required placeholder='Enter the Price For Each KIT' />


                  <button style={{ width: "400px" }}>GENERATE RANDOM KITS</button>
                </form>

                {addkit.length !== 0 && <>

                  <div className='imp' style={{ maxHeight: '248px' }}>
                    <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                      <thead className='tablephead'>
                        <tr>
                          <th>S NO.</th>
                          <th>Kit ID</th>
                          <th>Kit Price</th>
                          <th>Kit Type</th>
                          <th></th>


                        </tr>
                      </thead>
                      <tbody>
                        {console.log(addkit)}
                        {addkit.map((kit, index) => (

                          <tr>
                            <td>{sno1++}</td>

                            <td>{kit.prefix}{kit.Kit} </td>
                            <td>{kit.Kitprice} </td>
                            <td> Candida Profile </td>
                            <td className='assignbuttom' ><AiFillDelete className='hovar' onClick={() => RemoveItemByIndex(index)} style={{ width: '30px', height: '30px', color: 'red' }} /></td>

                          </tr>
                        ))}










                      </tbody>
                    </table>
                  </div>
                </>}

              </>}

              {popup2 === "assigeto" && <>

                <div className='header' >
                  <h4>Assign ({kitid}) Kits to:</h4>

                  {dooption2()}

                  {searchdone2 === false && <>
                    <form onSubmit={handleInputChange2} style={{ width: "fit-content" }}>
                      <AutoComplete
                        type="number"
                        style={{ width: 200 }}
                        options={options2}
                        placeholder="Search by Email/Name"
                        filterOption={(inputValue, options) =>
                          options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          //  console.log(kitss) 
                        }
                        onChange={(inputValue) => setSearchTerm2(inputValue)}
                      />
                      <button>Search</button>
                    </form>

                  </>}
                  {searchdone2 === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm2}</h3> <button onClick={clearsearch2}><AiOutlineClose /> Clear</button>  </div>
                  </>}
                </div>


                <div className='userbox'>


                  {practitioner2.map((item, index) =>

                    <div onClick={() => setpractitionerid(item._id)} className={practitionerid === item._id ? 'userboxxinside slecteddd' : 'userboxxinside'}>

                      <img alt='' src={item.profilepic} width={"20%"} />
                      <div>
                        <h5>Name: {item.name}</h5>
                        <h5>email: {item.email}</h5>
                        <h5>Address:{item.address}</h5>
                        <h5>phone:{item.phone}</h5>
                      </div>
                    </div>


                  )}







                </div>

              </>}



              {popup2 === "assigeto2" && <>

                <div className='header' >
                  <h4>Assign All Kits to:</h4>

                  {dooption2()}

                  {searchdone2 === false && <>
                    <form onSubmit={handleInputChange2} style={{ width: "fit-content" }}>
                      <AutoComplete
                        type="number"
                        style={{ width: 200 }}
                        options={options2}
                        placeholder="Search by Email/Name"
                        filterOption={(inputValue, options) =>
                          options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          //  console.log(kitss) 
                        }
                        onChange={(inputValue) => setSearchTerm2(inputValue)}
                      />
                      <button>Search</button>
                    </form>

                  </>}
                  {searchdone2 === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm2}</h3> <button onClick={clearsearch2}><AiOutlineClose /> Clear</button>  </div>
                  </>}
                </div>


                <div className='userbox'>


                  {practitioner2.map((item, index) =>

                    <div onClick={() => setpractitionerid(item._id)} className={practitionerid === item._id ? 'userboxxinside slecteddd' : 'userboxxinside'}>

                      <img alt='' src={item.profilepic} width={"20%"} />
                      <div>
                        <h5>Name: {item.name}</h5>
                        <h5>email: {item.email}</h5>
                        <h5>Address:{item.address}</h5>
                        <h5>phone:{item.phone}</h5>
                      </div>
                    </div>


                  )}







                </div>

              </>}



              {popup2 === "kitdetails" && <>

                <div className='header' >
                  <h2>Kits Details</h2>
                </div>
                <div className='kitdetailsmain' >




                  <div className='kitdetails'>
                    <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <GiFirstAidKit style={{ width: '20px', height: '20px', margin: "0 10px" }} /> KIT ID</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {kitdetails.prefix}{kitdetails.kitid} </h3>
                  </div>


                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {findpracname()}  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>  <MdEditSquare className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} onClick={() => {
                    setpopup(true)
                    setpopup2("assigeto")
                    setkitid(kitdetails.kitid)
                    setkitiddb(kitdetails._id)

                  }} /></>}</h3></div>


                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdAttachEmail style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {findpracemail()} </h3>
                  </div>


                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlinePendingActions style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Status</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {Kitsstaus()}

                    {kitdetails.assignedto ? (<>
                      {Kitsstaus() !== "Kit Not received in lab yet." ? (<>
                        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                          <Dropdown menu={{
                            items:

                              Kitsstaus() === "Results Published" ? itemsstatus2 : itemsstatus1



                          }} placement="bottomRight" arrow>
                            <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                          </Dropdown></>}
                      </>) : (<></>)}
                    </>) : (<></>)}

                  </h3></div>


                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlinePayments style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Payment</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}> € {kitdetailsKitprice()}

                    {kitdetails.assignedto ? (<>
                      <Dropdown menu={{
                        items:
                          kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf" ? token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ? items2 : items1 : token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ? items : items1


                      }} placement="bottomRight" arrow>
                        <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                      </Dropdown>
                    </>) : (<></>)}

                  </h3>
                  </div>


                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbReportMoney style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Payment Status</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>    {kitdetailsKitpricestatus()}  </h3>
                  </div>




                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbGitBranchDeleted style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action </h3><h3 onClick={async () => {
                    const hide = message.loading("Action in progress", 0)
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                    var urlencoded = new URLSearchParams();
                    urlencoded.append("_id", kitdetails._id);

                    var requestOptions = {
                      method: 'POST',
                      headers: myHeaders,
                      body: urlencoded,
                      redirect: 'follow'
                    };

                    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
                      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltkits", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                          setkit(result)
                          setpopup(false)
                          setaddkit2("")
                        })
                        .catch(error => console.log('error', error));


                    }
                    else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {
                      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltkits", requestOptions)

                        .catch(error => console.log('error', error));

                      var urlencoded2 = new URLSearchParams();
                      urlencoded2.append("id", id);

                      var requestOptions2 = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded2,
                        redirect: 'follow'
                      };


                      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
                        .then(response => response.json())
                        .then(result => {
                          setkit(result)
                          setpopup(false)
                          setaddkit2("")
                        })
                        .catch(error => console.log('error', error));
                    }

                    await setTimeout(() => {
                      hide(); // Call hide to stop the loading message
                      message.success("Action completed successfully");
                    }, 2000);


                  }} className='hovar' style={{ display: "flex", color: 'red', alignItems: 'center' }}>  Detele This Kit <AiFillDelete style={{ width: '20px', height: '20px', margin: "0 10px" }} /></h3>
                  </div>


                </div>
              </>}


              <div className='bownpopupbutton'>
                <button onClick={() => {
                  setpopup(false)
                  setaddkit([])
                  setaddkit2("")
                }
                } style={{ border: '1px solid red', color: 'black' }} >cancel</button>


                {popup2 === "addkit" && <>
                  <button onClick={() => setpopup2("assigeto2")} style={{ backgroundColor: '#4180b7' }}>ASSIGN ALL</button>
                  <button onClick={sendnewkittobackend} style={{ backgroundColor: '#4180b7' }}>Submit</button>
                </>}

                {popup2 === "assigeto" && <>
                  <button onClick={assignkittopra} style={{ backgroundColor: '#4180b7' }}>Assign</button>
                </>}

                {popup2 === "assigeto2" && <>
                  <button onClick={assignkittopra2} style={{ backgroundColor: '#4180b7' }}>Assign</button>
                </>}


              </div>

            </div>
          </>}





          {/* 
          <div>


            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th>S NO.</th>
                  <th>Kit ID</th>
                  <th>Practitioner</th>
                  <th>Status</th>
                  <th>Result</th>
                  <th>Action</th>
                
                </tr>
              </thead>
              <tbody>

                <tr>
                <td>dxsfc</td>
                </tr>
                </tbody>
            </table>

          </div> */}



















          {kit.length !== 0 && <>
            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th>S NO.</th>
                  <th>Kit ID</th>
                  {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>     <th>Assigned to</th></>}
                  {/* <th>Type</th> */}
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>


                {kit.map((item, index) => (<>
                  {
                    item.Kittype === "cp" && <>

                      <tr>
                        <td>{sno2++}</td>
                        <td>{item.prefix}{item.kitid}</td>
                        {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <> 
                          {item.assignedto ? (
                            <td style={{ display: "flex", flexDirection: 'column', textAlign: 'left', }}>
                              {practitioner.map((item2, index) => (<>

                                {item2._id === item.assignedto && <>

                                  <td style={{ border: "none", width: '50%' }}>{item2.name} </td>
                                  <td style={{ border: "none", width: '50%' }}>{item2.email}</td>
                                </>}


                              </>))}
                            </td>
                          ) : (
                            <td className='hovar' style={{ color: '#4180B7', display: "flex", alignItems: 'center', justifyContent: "center" }} onClick={() => {
                              setpopup(true)
                              setpopup2("assigeto")
                              setkitid(item.kitid)
                              setkitiddb(item._id)

                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



                              var requestOptions = {
                                method: 'GET',
                                headers: myHeaders,

                                redirect: 'follow'
                              };

                              fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
                                .then(response => response.json())
                                .then(result => setpractitioner(result))



                            }} >< MdAssignmentReturn style={{ width: '30px', height: '30px', color: '#4180B7' }} /><h5> Assign</h5></td>

                          )}


                        </>}
                        {/* <td> Fs {item.Kittype}</td> */}


                        {!item.assignedto ? (<>
                          {item.otherresults === "nil" && <>
                            <td>Kits Created</td>
                          </>}


                        </>) : (<>
                          {item.otherresults !== "nil" && <>
                            <td><a style={{ textDecoration: "none", fontWeight: 'bold', color: '#6E4E9F' }} href={item.otherresults} >View result</a></td>
                          </>}

                          {item.otherresults === "nil" && <>
                            <td>Results Pending</td>
                          </>}

                        </>)}


                        {/* {item.Kitprice !== "fdgbhjbdgfhjdfgsbf" && <>


                      <td>




                        {item.Kitprice}

                        <button onClick={async () => {

                          const stripe = await loadStripe("pk_live_51MsenqJSzdsymN5jGOTIP3q4qBmD4PDra9chWFQYDC6RCchx2jLlIgdDRrUnhI24QhZeNeAqEo9tc6l3oiR4SWc3000yjqG8qW");

                          var myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                          var urlencoded = new URLSearchParams();
                          urlencoded.append("KitID", item._id);

                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                          };


                          const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/create-checkout-session", requestOptions)

                          const session = await response.json();

                          const result = stripe.redirectToCheckout({
                            sessionId: session.id
                          });

                          if (result.error) {
                            console.log(result.error);
                          }



                        }} className='button'>Pay</button></td>






                    </>} */}
                        <td>
                          <button className='button'
                            onClick={() => {
                              setpopup(true)
                              setpopup2("kitdetails")
                              setkitdetails(item)
                            }}

                          > Details</button>
                        </td>

                        {/* {item.Kitprice === "fdgbhjbdgfhjdfgsbf" && <> <td>Done</td></>} */}

                        {/* <td className='assignbuttom' ><AiFillDelete className='hovar' style={{ width: '30px', height: '30px', color: 'red' }}
                      onClick={async () => {

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                        var urlencoded = new URLSearchParams();
                        urlencoded.append("_id", item._id);

                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };
                        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
                          await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltkits", requestOptions)
                            .then(response => response.json())
                            .then(result => setkit(result))
                            .catch(error => console.log('error', error));


                        } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {
                          await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltkits", requestOptions)

                            .catch(error => console.log('error', error));

                          var urlencoded2 = new URLSearchParams();
                          urlencoded2.append("id", id);

                          var requestOptions2 = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded2,
                            redirect: 'follow'
                          };


                          await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
                            .then(response => response.json())
                            .then(result => setkit(result))
                            .catch(error => console.log('error', error));



                        }




                      }}


                    /></td> */}

                      </tr>
                    </>
                  }
                </>))}






              </tbody>
            </table>


          </>}

          {kit.length === 0 && <>

            <img alt='' src='/empty.gif' width={"40%"} />
          </>}

        </div>


      </div>
    </>}
  </>)
}

export default Candidakit