import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Checkbox, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../css/orderlables.css"
import html2pdf from 'html2pdf.js';


function Orderlables() {
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")


  const [popup, setpopup] = useState(false)
  const [print, setprint] = useState(false)
  const [popup2, setpopup2] = useState("addkit")

  const [orders, setorders] = useState([])
  const [labels, setLabels] = useState([])
  const [seequetion, setseequetion] = useState(true)

  const [orderdetails, setorderdetails] = useState([])

  const [totalPages, settotalPages] = useState(1);

  const getorders = async (pagesss) => {

    const hide = message.loading("Action in progress", 0)

    let woocommerceApiUrl = ''; // Replace with your actual URL
    let consumerKey = ''; // Replace with your generated Consumer Key
    let consumerSecret = ''; // Replace with your generated Consumer Secret

    if (seequetion) {
       woocommerceApiUrl = 'https://yourgutmap.co.uk/wp-json/wc/v3/'; // Replace with your actual URL
       consumerKey = 'ck_3baf06e2c37cf5491ac07bc50bd423ac32d2e0f2'; // Replace with your generated Consumer Key
       consumerSecret = 'cs_93c79b982026b949b8cb7c8a820ca3d4fcb460e8'; // Replace with your generated Consumer Secret

    }else{
      woocommerceApiUrl = 'https://ireland.yourgutmap.co.uk/wp-json/wc/v3/'; // Replace with your actual URL
      consumerKey = 'ck_3baf06e2c37cf5491ac07bc50bd423ac32d2e0f2'; // Replace with your generated Consumer Key
      consumerSecret = 'cs_93c79b982026b949b8cb7c8a820ca3d4fcb460e8'; // Replace with your generated Consumer Secret

    }

    async function getAllOrders() {
      const encodedCredentials = btoa(`${consumerKey}:${consumerSecret}`);
      const headers = {
        'Authorization': `Basic ${encodedCredentials}`,
      };

      let orders = [];
      let page = pagesss;
      let totalPages = 1;

      try {

        const response = await axios.get(`${woocommerceApiUrl}orders`, {
          headers,
          params: {
            per_page: 30, // Number of orders per page (max 100)
            page: page
          }
        });

        orders = response.data
        settotalPages(parseInt(response.headers['x-wp-totalpages'], 10));



        return orders;
      } catch (error) {
        console.error('Error fetching orders:', error);
        return null; // Or handle the error appropriately
      }
    }

    try {
      const orders = await getAllOrders();
      setorders(orders);
    } catch (error) {
      console.error('Error:', error);
    }

    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  };



  useEffect(() => {
    getorders(currentPage)


  }, [seequetion])




  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = async (pageNumber) => {

    const hide = message.loading("Action in progress", 0)

    await getorders(pageNumber)
    await setCurrentPage(pageNumber);

    await renderPageNumbers()

    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  };



  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
    const startPage = Math.max(1, currentPage - maxPageToShow);
    const endPage = Math.min(totalPages, currentPage + maxPageToShow);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? 'activeeee' : ''}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };


  const isShippingChecked = (shipping, id) => {
    return labels.some(label => label.id === id);
  };

  const handleCheckboxChange = (e, shipping, id, items) => {
    const shippingWithId = { ...shipping, id, items };

    if (e.target.checked) {
      setLabels(prevLabels => [...prevLabels, shippingWithId]);
    } else {
      setLabels(prevLabels => prevLabels.filter(label => label.id !== id));
    }
  };





  const RemoveItemByIndex = (index) => {
    // Create a new array without the item at the specified index
    const updatedAddkit = labels.filter((_, i) => i !== index);
    setLabels(updatedAddkit);
  };

  useEffect(() => {
    if (labels.length === 0) {
      setpopup(false)

      setpopup2("addkit")
    }
  }, [labels]);








  const handlePrint = async () => {
    await setprint(true)

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // getMonth() is zero-based
    const year = currentDate.getFullYear();

    // Format date as DD/MM/YYYY
    const formattedDate = `${day}/${month}/${year}`;

    const content = await document.getElementById('print'); // Replace with the ID of the content you want to export


    const opt = {
      margin: 0,
      filename: 'my_webpage.pdf',
      image: { type: 'jpeg', quality: 2 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const seee = html2pdf().from(content).set(opt).outputPdf();



    seee.save("order shipping - " + formattedDate + ".pdf")

    // await setprint(false)
    // await setpopup(false)
    // await setLabels([])

  };

  const handlePrintname = async () => {
    await setprint(true)

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // getMonth() is zero-based
    const year = currentDate.getFullYear();

    // Format date as DD/MM/YYYY
    const formattedDate = `${day}/${month}/${year}`;

    const content = await document.getElementById('printt'); // Replace with the ID of the content you want to export


    const opt = {
      margin: 0,
      filename: 'my_webpage.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const seee = html2pdf().from(content).set(opt).outputPdf();



    seee.save("order Name - " + formattedDate + ".pdf")

    await setprint(false)
    await setpopup(false)
    await setLabels([])

  };

  var sno1 = 1
  var sno2 = 1
  var sno3 = 1

 
  return (
    <div>
      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && <>



        <Navbar />

        <div className='deshboardmain'>
          <Sidemanu />

          <div className='mainbody'>

            <div className='header'>
              <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><CgReorder style={{ width: '50px', height: '50px' }} />Orders information</h1>


            </div>
            <div style={{ display: 'flex' }}>
              {seequetion ? (<>

                <h4 className='qqqqqqq qqqqqqqqqqq' style={{ display: 'flex', margin: "0" }}> UK ORDERS</h4>
                <h4 className='qqqqqqq ' onClick={() => setseequetion(!seequetion)} style={{ display: 'flex', margin: "0" }}> IR ORDERS</h4>

              </>) : (<>


                <h4 className='qqqqqqq ' onClick={() => setseequetion(!seequetion)} style={{ display: 'flex', margin: "0" }}> UK ORDERS</h4>
                <h4 className='qqqqqqq qqqqqqqqqqq' style={{ display: 'flex', margin: "0" }}> IR ORDERS</h4>

              </>)}

            </div>


            {popup === true && <>
              <div onClick={() => {
                setpopup(false)

                setpopup2("addkit")
              }
              } className='popupbg'></div>
              <div className='popup'>

                {popup2 === "addkit" && <>

                  <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                    <thead className='tablephead'>
                      <tr>
                        <th>S No.</th>
                        <th>Name	</th>
                        <th>Address</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>company</th>
                        <th>Action</th>


                      </tr>
                    </thead>
                    <tbody>
                      {labels.map((value, index) => {

                        return (
                          <tr key={index}>
                            <td>{sno1++}</td>
                            <td>{value.first_name} {value.last_name}</td>
                            <td>{value.address_1}<br />{value.address_2}</td>
                            <td>{value.country}</td>
                            <td>{value.city}</td>
                            <td>{value.company}</td>
                            <td className='assignbuttom' ><AiFillDelete className='hovar' onClick={() => RemoveItemByIndex(index)} style={{ width: '30px', height: '30px', color: 'red' }} /></td>



                          </tr>
                        );
                      })}
                    </tbody>
                  </table>



                </>}



                {popup2 === "kitdetails" && <>

                  <div className='header' >
                    <h2>Order #{orderdetails.id}</h2>
                    <h2>{orderdetails.status}</h2>
                  </div>
                  <div className='kitdetailsmain' >


                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ textAlign: 'left' }}>
                        <h3>Billing details</h3>

                        {orderdetails.billing.first_name} {orderdetails.billing.last_name}<br />

                        {orderdetails.billing.email}<br />
                        {orderdetails.billing.address_1}<br />
                        {orderdetails.billing.address_2}<br />

                        {orderdetails.billing.city}<br />
                        {orderdetails.billing.postcode}<br /><br />
                        Email<br />
                        {orderdetails.billing.email}<br /><br /><br />
                        Phone<br />
                        {orderdetails.billing.phone}<br /><br />
                        Payment via<br />
                        {orderdetails.payment_method_title} ({orderdetails.transaction_id})<br /><br />
                      </div>



                      <div style={{ textAlign: 'left' }}>
                        <h3>Shipping details</h3>
                        {orderdetails.shipping.first_name} {orderdetails.shipping.last_name}<br />

                        {orderdetails.shipping.company}<br />
                        {orderdetails.shipping.address_1}<br />
                        {orderdetails.shipping.address_2}<br />
                        {orderdetails.shipping.city}<br />
                        {orderdetails.shipping.postcode}<br /><br />
                        Shipping method<br />
                        {orderdetails.shipping_lines[0].method_title}
                      </div>
                    </div>




                    <div>
                      <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                        <thead className='tablephead'>
                          <tr>
                            <th>Product</th>
                            <th>Quantity	</th>
                            <th>Total</th>


                          </tr>
                        </thead>
                        <tbody>
                          {orderdetails.line_items.map((order, index) => {

                            return (
                              <tr key={index}>
                                <td>{order.name}</td>
                                <td>{order.quantity}</td>
                                <td>&euro;{order.subtotal}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>




                  </div>
                </>}


                <div className='bownpopupbutton'>
                  <button onClick={() => {
                    setpopup(false)

                  }
                  } style={{ border: '1px solid red', color: 'black' }} >cancel</button>


                  {popup2 === "addkit" && <>
                    <button onClick={handlePrint} style={{ backgroundColor: '#4180b7' }}>Print Labels</button>
                    <button onClick={handlePrintname} style={{ backgroundColor: '#4180b7' }}>Print Name</button>
                  </>}




                </div>

              </div>
            </>}


            <div style={{ margin: "50px" }}></div>
            <div className="pagination">
              {currentPage > 1 && (
                <span onClick={() => handlePageClick(currentPage - 1)}>&lt;&lt;</span>
              )}
              {renderPageNumbers()}
              {currentPage < totalPages && (
                <span onClick={() => handlePageClick(currentPage + 1)}>&gt;&gt;</span>
              )}

              {labels.length !== 0 && <><button className='button' onClick={() => {
                setpopup(true)
                setpopup2("addkit")
              }}>Print</button></>}
            </div>



            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th></th>

                  <th>Order</th>
                  <th>Date</th>

                  <th>Status</th>
                  <th>Total</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>




                {orders.map((value, index) => {
                  return (<>
                    <tr>
                      <td ><Checkbox checked={isShippingChecked(value.shipping, value.id, value.line_items)}
                        onChange={(e) => handleCheckboxChange(e, value.shipping, value.id, value.line_items)}
                      ></Checkbox></td>
                      {/* <td>{isShippingChecked(value.shipping)}</td> */}
                      <td >#{value.id} {value.billing.first_name} {value.billing.last_name}</td>
                      <td >{value.date_created}</td>
                      <td >{value.status}</td>
                      <td >{value.currency_symbol}{value.total}</td>
                      <td>
                        <button className='button'
                          onClick={() => {
                            setpopup(true)
                            setpopup2("kitdetails")
                            setorderdetails(value)
                          }}

                        > Details</button>
                      </td>


                    </tr>
                  </>)
                })}















              </tbody>
            </table>







          </div>

        </div>

        <div id='print' className='printt' style={{ display: print ? 'block' : 'none' }} >
          <div className='prrintbox'>
            {labels.map((value, index) => (
              <div className='labelprint' key={index}>
                <div className='insideprint'>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontWeight: 'bold' }}> {value.first_name} {value.last_name}</div>
                    <div>  {value.company}</div>
                    <div>   {value.address_1}</div>
                    <div>   {value.address_2}</div>

                    <div>   {value.city}</div>
                    <div style={{ fontWeight: 'bold' }}>   {value.postcode}</div>

                  </div>
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontWeight: 'bold' }}>{value.id}</div>
                    <div><img className='printimg' src='/logo2.png' width={"100px"} /></div>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        <div id='printt' className='printt' style={{ display: print ? 'block' : 'none' }} >
          <div className='prrintbox2' style={{}}>
            {labels.map((value, index) => (
              // <div className='labelprint' key={index}>
              //  <div className='insideprint'>
              //   <div style={{ display: 'flex', flexDirection: 'column' }}>
              //     <div style={{ fontWeight: 'bold' }}> {value.first_name} {value.last_name}</div>
              //     <div>  {value.company}</div>
              //     <div>   {value.address_1}</div>
              //     <div>   {value.address_2}</div>

              //     <div>   {value.city}</div>
              //     <div style={{ fontWeight: 'bold' }}>   {value.postcode}</div>

              //   </div>
              //   <div style={{ textAlign: "center" }}>
              //     <div style={{ fontWeight: 'bold' }}>{value.id}</div>
              //     <div><img className='printimg' src='/logo2.png' width={"100px"} /></div>

              //   </div>
              //   </div>
              // </div>
              <div>
                <div style={{ fontWeight: 'bold', marginTop: '50px' }}> {value.first_name} {value.last_name}</div>
                <div>
                  <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                    <thead className='tablephead'>
                      <tr>
                        <th>Product</th>
                        <th>Quantity	</th>
                        <th>Total</th>


                      </tr>
                    </thead>
                    <tbody>

                      {value.items.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td>{order.name}</td>
                            <td>{order.quantity}</td>
                            <td>&euro;{order.subtotal}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}


          </div>
        </div>

      </>}
    </div>
  )
}

export default Orderlables