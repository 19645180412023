import React, { useEffect, useRef, useState } from 'react'
import "../css/pararesult.css"
import html2pdf from 'html2pdf.js';
import { useParams } from 'react-router-dom';

function Pararesults() {
    const { id } = useParams();
    const [isPdfMode, setIsPdfMode] = useState(false);
    const [kit, setkit] = useState([]);
    const [ackKit, setackKit] = useState([])
    const contentRef = useRef();

    const getdata = async () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("_id", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions)
            .then(response => response.json())
            .then(result => {
                setkit(result)
              
            })
            .catch(error => console.log('error', error));


        var requestOptions2 = {
            method: 'GET',
            headers: myHeaders,

            redirect: 'follow'
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallemaidata", requestOptions2)
            .then(response => response.json())
            .then(result => console.log(result))

    }



    useEffect(() => {


        getdata()
        // sumofresult()

    }, [])


    const handleDownloadPDF = async () => {
        await setIsPdfMode(true)


        const content = await document.getElementById('ali'); // Replace with the ID of the content you want to export


        const opt = {
            margin: 0,
            filename: "YourGutMap - Parasitology result " + kit.prefix + kit.kitid + ".pdf",
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        const seee = html2pdf().from(content).set(opt).outputPdf();



        seee.save("YourGutMap - Parasitology result " + kit.prefix + kit.kitid + ".pdf")
        await setIsPdfMode(false)


    };





    const receiveddate = (date) => {
        let daterec = date
        const result = ackKit.filter(item => item.KitId === date);
        console.log(result)
        return result.timestemp
    }
    return (<>
        <div>
            <button style={{ top: "194mm" }} className='downloadbuttonviewreport' onClick={handleDownloadPDF}>Download</button>
        </div>
        <div className='mainnnnnnnnn' >
            <div id='ali'   >
                <div className="firstdiv">
                    <div className="firstdivhead">

                        <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                        <img src="/uper.png" alt="" />
                    </div>
                    <div className="firstdivcenter">

                        <img src='/paralogo.jpg' alt='' />

                    </div>

                    <h1 className='testreport_heading'>Test Report</h1>
                    <div style={{ color: '#6E4E9F' }}>
                        <h2>Name   : {kit.patientName}</h2>
                        <h2>Kit ID : {kit.prefix}{kit.kitid}</h2>
                    </div>
                    <div className="firstdivend">

                        <img src="/lower.png" alt="" />
                    </div>




                </div>

                <div className="secenddiv">
                    <div className="firstdivhead">
                        <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                        <img src="/uper.png" alt="" />



                    </div>



                    <div className="secenddivcenter">

                        <div className="report">
                            <h2>Gastrointestinal Test Report</h2>
                        </div>
                        <div className="reporttext">
                            <div className="reportleft">
                                <span>
                                    <h4>sample ID:</h4>
                                    <h5>{kit.prefix}{kit.kitid}</h5>
                                    <h4> Specimen:</h4>
                                    <h5 style={{ borderTop: "1px solid #fff" }}>{kit.SampleType}</h5>
                                </span>
                                <span>

                                    <h4>Received Date:</h4>
                                    <h5>{receiveddate(kit.kitid)}</h5>
                                    <h4> Completed Date:</h4>
                                    <h5>{kit.resultDate}</h5>
                                </span>

                                {/* <span>

                                <h4>Received Date:</h4>
                                <h5>2024-10-07 10:45:00</h5>
                                <h4> Completed Date:</h4>
                            </span> */}

                            </div>
                            {/* <div className="reportright">
                            <h4>Fecal - Native</h4>
                            <h4>2024-10-10 13:09:39</h4>
                        </div> */}


                        </div>

                        <div className="maindivvvv">


                            <div className="maindivvvvleft">
                                <div className="result"><h2>Test Result</h2></div>
                                <div className="boxhead">
                                    <h4>Pathogenic Bacteria
                                    </h4>

                                </div>
                                <div className="box">


                                    <table>
                                        <tbody>
                                            <tr className={kit.Helicobacterpylori === "detected" ? 'colorchange' : ""}>
                                                <td >Helicobacter pylori</td>
                                                <td  >{kit.Helicobacterpylori}</td>
                                            </tr>

                                            <tr className={kit.Clostridiumdifficile === "detected" ? 'colorchange' : ""}>

                                                <td>Clostridium difficile</td>
                                                <td   >{kit.Clostridiumdifficile}</td>
                                            </tr>

                                            <tr className={kit.Plesiomonasshigelloides === "detected" ? 'colorchange' : ""}>
                                                <td>plesiomonas shigelloides</td>
                                                <td>{kit.Plesiomonasshigelloides}</td>
                                            </tr>


                                            <tr className={kit.Salmonella === "detected" ? 'colorchange' : ""}>
                                                <td>Salmonella</td>
                                                <td>{kit.Salmonella}</td>
                                            </tr>


                                        </tbody>


                                        <tbody>



                                            <tr className={kit.Yersiniaenterocolitica === "detected" ? 'colorchange' : ""}>

                                                <td>Yersinia enterocolitica</td>
                                                <td>{kit.Yersiniaenterocolitica}</td>
                                            </tr>
                                            <tr className={kit.Campylobacterjejunicoli === "detected" ? 'colorchange' : ""}>
                                                <td>Campylobacter jejuni/coli</td>
                                                <td>{kit.Campylobacterjejunicoli}</td>
                                            </tr>

                                            <tr className={kit.Vibriocholerae === "detected" ? 'colorchange' : ""}>

                                                <td>Vibrio cholerae</td>
                                                <td>{kit.Vibriocholerae}</td>
                                            </tr>


                                            <tr className={kit.EcoliO157 === "detected" ? 'colorchange' : ""}>
                                                <td>E. coli O157</td>
                                                <td>{kit.EcoliO157}</td>
                                            </tr>

                                        </tbody>


                                        <tbody>




                                            <tr className={kit.EnteroaggregativeEcoliEAEC === "detected" ? 'colorchange' : ""}>

                                                <td>Enteroaggregative E. coli (EAEC) </td>
                                                <td > {kit.EnteroaggregativeEcoliEAEC}</td>
                                            </tr>
                                            <tr className={kit.EnteropathogenicEcoliEPEC === "detected" ? 'colorchange' : ""}>
                                                <td>Enteropathogenic E. coli (EPEC) </td>
                                                <td>{kit.EnteropathogenicEcoliEPEC}</td>
                                            </tr>

                                            <tr className={kit.EnterotoxigenicEcoliETEC === "detected" ? 'colorchange' : ""}>

                                                <td>Enterotoxigenic E. coli (ETEC) LT</td>
                                                <td>{kit.EnterotoxigenicEcoliETEC}</td>
                                            </tr>

                                            <tr className={kit.ShigatoxinproducingEcoliSTEC === "detected" ? 'colorchange' : ""}>
                                                <td>Shiga-Toxin-producing E. coli (STEC) </td>
                                                <td>{kit.ShigatoxinproducingEcoliSTEC}</td>
                                            </tr>

                                        </tbody>



                                        <tbody>


                                            <tr className={kit.EnteroinvasiveEcoliEIEC === "detected" ? 'colorchange' : ""}>
                                                <td>Enteroinvasive E. coli (EIEC) </td>
                                                <td>{kit.EnteroinvasiveEcoliEIEC}</td>
                                            </tr>



                                        </tbody>



                                    </table>


                                </div>





                                <div className="boxhead">
                                    <h4> Viruses                                    </h4>

                                </div>
                                <div className="box">


                                    <table>



                                        <tbody>





                                            <tr className={kit.Adenovirus === "detected" ? 'colorchange' : ""}>
                                                <td>Adenovirus </td>
                                                <td>{kit.Adenovirus}</td>
                                            </tr>

                                            <tr className={kit.Astrovirus === "detected" ? 'colorchange' : ""}>
                                                <td>Astrovirus</td>
                                                <td >{kit.Astrovirus}</td>
                                            </tr>
                                            <tr className={kit.NorovirusGIGII === "detected" ? 'colorchange' : ""}>
                                                <td>Norovirus GI/GII</td>
                                                <td>{kit.NorovirusGIGII}</td>
                                            </tr>
                                            <tr className={kit.RotavirusA === "detected" ? 'colorchange' : ""}>
                                                <td>Rotavirus A</td>
                                                <td>{kit.RotavirusA}</td>
                                            </tr>

                                        </tbody>

                                        <tbody>






                                            <tr className={kit.SapovirusIIIIVandV === "detected" ? 'colorchange' : ""}>
                                                <td>Sapovirus (I, II, IV, and V)</td>
                                                <td>{kit.SapovirusIIIIVandV}</td>
                                            </tr>

                                        </tbody>



                                    </table>


                                </div>



                                <div className="boxhead">
                                    <h4>Parasites</h4>

                                </div>
                                <div className="box">


                                    <table>



                                        <tbody>







                                            <tr className={kit.Cryptosporidiumspp === "detected" ? 'colorchange' : ""}>
                                                <td>Cryptosporidium spp</td>
                                                <td>{kit.Cryptosporidiumspp}</td>
                                            </tr>
                                            <tr className={kit.Cyclosporacayetanensis === "detected" ? 'colorchange' : ""}>
                                                <td>Cyclospora cayetanensis</td>
                                                <td>{kit.Cyclosporacayetanensis}</td>
                                            </tr>
                                            <tr className={kit.Entamoebahistolytica === "detected" ? 'colorchange' : ""}>
                                                <td>Entamoeba histolytica</td>
                                                <td>{kit.Entamoebahistolytica}</td>
                                            </tr>
                                            <tr className={kit.Giardialamblia === "detected" ? 'colorchange' : ""}>
                                                <td>Giardia lamblia</td>
                                                <td>{kit.Giardialamblia}</td>
                                            </tr>
                                        </tbody>



                                    </table>


                                </div>


                            </div>














                        </div>

                        <div className="information">
                            <div className="report">
                                <h3>Test Information</h3>

                            </div>
                            <div className="informationtext">
                                <p> <strong>Methodology: </strong>Laboratory specimens associated with this report were analyzed using molecular techniques. Total nucleic
                                    acids were extracted from the submitted sample and analyzed by PCR amplification using real-time qPCR. Endogenous and
                                    exogenous controls run simultaneously with patient samples ensure the correct operation of the extraction and PCR steps of
                                    this assay</p>
                                <p><strong>Disclaimer: </strong> Results should be used in conjunction with clinical findings, and should not form the sole basis for a diagnosis or
                                    treatment decision. Negative results do not preclude pathogenic infection and should not be used as the sole basis for
                                    patient management decisions. Negative results must be combined with clinical observations, patient history, and
                                    epidemiological information.</p>
                            </div>
                        </div>

                    </div>


                    <div className="secenddivend">

                        <img src="/lower.png" alt="" />
                    </div>



                </div>






                {/* Helicobacter pylori * */}
                {kit.Helicobacterpylori === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Helicobacter pylori</h2>


                            </div>

                            <div className="lastdivtext">

                                <p> <strong>About <br /></strong>
                                    H. pylori is a tiny, spiral-shaped bacterium. It has flagella (bacterial tail), which allows it to move
                                    about and attach itself to the stomach lining, where it's able to survive in the acidic environment
                                    of the stomach. Whilst carrying this bacteria often causes no symptoms at all for many people, it
                                    can play a large contribution to stomach pain, ulcers as well as a number of digestive issues (1).
                                    That said, H.pylori is a bacterium that is normal in low numbers and it is when this bacterium
                                    over colonises the stomach is where potential problems can occur.</p>

                                <p><strong>Advice<br /></strong>
                                    There are also a number of dietary supplements shown to be helpful in the treatment of H.
                                    pylori, including: Berberine, Goldenseal, Oregano oil, Mastic gum, DGL licorice, Zinc carnosine,
                                    propolis, Green tea, Garlic and Probiotics. Using the below treatments has been shown to be
                                    effective in up to 70% of cases (2,3,4,5). One particular study found efficacy with the following
                                    protocol:</p>

                                <p>
                                    <ul>
                                        <li>Mastic gum 500mg, 3 times daily</li>
                                        <li> ADP oregano 50mg, 3 times daily</li>
                                        <li>  Pepto bismol 4-6 tabs between meals daily</li>
                                        <li>  Broad Spectrum Probiotic 5 billion, twice daily</li>
                                        <li>  Fibre Blend supplement 14g/day (Psyllium Husk, etc)</li>
                                    </ul>
                                </p>

                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}





                {/* Clostridium difficile* */}
                {kit.Clostridiumdifficile === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Clostridium difficile</h2>


                            </div>

                            <div className="lastdivtext">

                                <p> <strong>About <br /></strong>
                                    Clostridium difficile is an anaerobic, spore-forming, Gram-positive bacterium, the most common
                                    pathogen that can cause antibiotic-associated diarrhoea. Normal gut flora usually resists
                                    colonisation and overgrowth of C. difficile. Subsequent inflammatory activation in addition to the
                                    direct toxin-associated damage resulting in mild diarrheal disease up to extensive intestinal wall
                                    damage. The incidences of C. difficile infection (CDI) in developed countries have become
                                    increasingly high which may be attributed to the emergence of newer epidemic strains,
                                    extensive use of antibiotics, and limited alternative therapies.</p>

                                <p><strong>Advice<br /></strong>
                                    Virgin coconut oil is found to have an antimicrobial activity. The active fatty acids have been
                                    tested for their antimicrobial potential against C. difficile in vitro trail. The results have shown the
                                    inhibitory effects on growth.</p>

                                <p>
                                    In a recently published study, researchers have investigated both herbal extracts of Black seed
                                    soil and Myrrh for their antibacterial activity against two strains of C. difficile. The study found
                                    that Black Seed Seed oil (2%) and Myrrh water extract are effective natural antibacterial agents
                                    to inhibit C. difficile (1). One particular case study used black seed oil, bentonite clay, and
                                    Lactobacillus probiotics to eradicate the infection in a 2 year old (2)
                                </p>

                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* plesiomonas shigelloides* */}
                {kit.Plesiomonasshigelloides === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Plesiomonas shigelloides</h2>


                            </div>

                            <div className="lastdivtext">

                                <p> <strong>About <br /></strong>
                                    Recent studies have suggested that Plesiomonas shigelloides is a cause of diarrhea and
                                    abdominal pain. Plesiomonas shigelloides is an anaerobic gram-negative bacillus commonly
                                    found in aquatic environments. It is an important pathogen of fish and has been implicated as a
                                    cause of enteric disease in humans, especially following the consumption of raw seafood. Most
                                    cases are usually obtained through tropical travel but can also be obtained locally from
                                    untreated water and/or seafood consumption. </p>

                                <p><strong>Advice<br /></strong>
                                    Infection of Plesiomonas shigelloides are often mild and may not always need treatments, apart
                                    from natural fluid & electrolyte support. For more chronic or severe cases, then some
                                    practitioners may recommend antibiotic therapy </p>


                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/*Salmonella **/}
                {kit.Salmonella === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Salmonella (Genus Group)</h2>


                            </div>

                            <div className="lastdivtext">

                                <p> <strong>About <br /></strong>
                                    Salmonella is a type of bacteria commonly found in the intestines of animals and humans,
                                    where it can sometimes cause infections. When introduced into the human digestive tract, often
                                    through contaminated food or water, it can lead to an illness known as salmonellosis. This
                                    infection causes symptoms like diarrhoea, fever, and abdominal cramps as the bacteria invade
                                    and inflame the gut lining. While most cases resolve on their own, severe infections may require
                                    medical treatment, particularly in young children, the elderly, or those with weakened immune
                                    systems. </p>

                                <p><strong>Advice<br /></strong>
                                    <strong>Hydration:</strong> The primary treatment is rehydration. Since diarrhoea can lead to fluid loss, drinking
                                    plenty of water or oral rehydration solutions is essential to prevent dehydration.
                                    <br />  <strong> Rest:</strong> Resting allows the body to focus its energy on fighting the infection.
                                    <br />   <strong> Diet:</strong> Eating light, bland foods (like bananas, rice, applesauce, and toast) can help ease
                                    symptoms and prevent irritation of the gut.
                                    <br />  <strong>Medication:</strong>  In severe cases, especially when the infection spreads beyond the intestines or in
                                    vulnerable groups (infants, elderly, or immunocompromised), antibiotics may be prescribed.
                                    However, antibiotics are typically avoided in mild cases, as they can prolong the time
                                    Salmonella bacteria stay in the body. </p>

                                <p>
                                    If symptoms persist for more than a week, are severe, or if there are signs of dehydration (such
                                    as dry mouth, reduced urination, or dizziness), seeking medical attention is advised.
                                </p>



                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}





                {/* Yersinia enterocolitica* */}
                {kit.Yersiniaenterocolitica === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Yersinia Enterocolitica</h2>


                            </div>

                            <div className="lastdivtext">

                                <p> <strong>About <br /></strong>
                                    Yersinia enterocolitica is a gram-negative bacteria that causes yersiniosis usually caused by
                                    drinking contaminated milk or water or eating raw or undercooked meat, usually pork (chitlins).
                                    Human-to-human infection is possible or from a blood transfusion.Yersinia enterocolitica is
                                    primarily known to cause acute inflammation in the gut. This bacteria infects the intestines
                                    causing diarrhoea (usually watery or bloody), vomiting, abdominal pain, and fever which lasts
                                    about 4-7 days but can last up to three weeks. Many infected individuals will suspect having
                                    food poisoning and usually symptoms disappear within a few days and if not, medical attention
                                    may be required. </p>

                                <p><strong>Advice<br /></strong>
                                    <strong>Hydration:</strong> The primary treatment is rehydration. Since  The microbiome rebalancing foods will help to rebalance the microbiome, making it more
                                    resilient against pathogenic parasites. If symptoms last longer than 1 week then an antimicrobial
                                    such as berberine or herbs high in chlorogenic acid (Green tea, elderflower,
                                    rosemary).<a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9117966/'>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9117966/</a>
                                    If symptoms are severe and persist after 8 weeks, then referral to a Doctor may be necessary. </p>

                                <p>
                                    If symptoms persist for more than a week, are severe, or if there are signs of dehydration (such
                                    as dry mouth, reduced urination, or dizziness), seeking medical attention is advised.
                                </p>



                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}





                {/* Campylobacter jejuni/coli* */}
                {kit.Campylobacterjejunicoli === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Campylobacter jejuni/coli</h2>


                            </div>

                            <div className="lastdivtext">

                                <p> <strong>About <br /></strong>
                                    <strong> Campylobacter jejuni</strong> and <strong>Campylobacter coli</strong> are bacterial species that are among the most
                                    common causes of foodborne illnesses in humans worldwide. Both belong to the genus
                                    <strong> Campylobacter,</strong> which includes several species known to cause <strong>gastroenteritis</strong>, or
                                    inflammation of the stomach and intestines, leading to symptoms like diarrhoea, abdominal
                                    pain, and fever. </p>

                                <p><strong>Advice<br /></strong>
                                    <strong>  Consume Anti-inflammatory Foods</strong>
                                    <ul style={{ margin: "0" }}>
                                        <li> <strong> Ginger:</strong>  Known for its anti-inflammatory and antimicrobial properties, ginger tea can help
                                            soothe an irritated gut and alleviate nausea.</li>
                                        <li>  <strong> Turmeric:</strong>  This spice has anti-inflammatory and antioxidant properties. Adding a small
                                            amount to food or taking it as a supplement may help reduce inflammation.</li>
                                        <li>  <strong> Garlic:</strong>  With natural antibacterial properties, garlic can support the immune system;
                                            however, it should be eaten in small amounts as it can be irritating to some people’s
                                            stomachs.</li>

                                    </ul>




                                    <strong>   Eat Easily Digestible Foods</strong>
                                    <ul style={{ margin: "0" }}>
                                        <li> Stick to a<strong>  BRAT diet</strong>   (bananas, rice, applesauce, and toast) to avoid irritating the
                                            digestive tract.</li>
                                        <li>  Include  <strong>  plain boiled potatoes, bone broth, and oatmeal, </strong>  which are gentle on the
                                            stomach and provide essential nutrients.</li>


                                    </ul>

                                    <strong>    Rest</strong>
                                    <ul style={{ margin: "0" }}>
                                        <li>  Rest is crucial because the body needs energy to fight the infection. Avoid strenuous
                                            activities, and prioritise sleep.</li>


                                    </ul>


                                </p>

                                <p>
                                    Most Campylobacter infections resolve within a week without antibiotics, but in severe cases,
                                    medical treatment may be necessary. If symptoms are severe (high fever, blood in stool, or
                                    prolonged dehydration), it’s best to consult a Doctor.
                                </p>



                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Vibrio cholerae* */}
                {kit.Vibriocholerae === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Vibrio cholerae</h2>


                            </div>

                            <div className="lastdivtext">

                                <p> <strong>About <br /></strong>
                                    Vibrio cholerae is a gram-negative bacterium that causes cholera, an acute gastrointestinal
                                    infection characterised by severe diarrhoea and dehydration. It is primarily transmitted through
                                    contaminated water or food, particularly in areas with inadequate sanitation and hygiene
                                    practices. Symptoms of cholera can develop suddenly and may include profuse watery
                                    diarrhoea (often described as "rice-water" stools), vomiting, abdominal cramps, and leg cramps.
                                    If left untreated, cholera can lead to rapid dehydration, electrolyte imbalances, and can be
                                    life-threatening. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>   Seek Immediate Medical Attention:</strong> Cholera can lead to severe dehydration very quickly, so
                                            it's crucial to seek medical help right away if you suspect you have cholera. Medical
                                            professionals can provide rehydration therapy and necessary medications.</li>
                                        <li> <strong>  Stay Hydrated</strong>   If you are unable to reach medical care immediately, begin rehydrating as soon
                                            as possible. Drink oral rehydration solutions (ORS) that contain electrolytes and sugars to help
                                            replace lost fluids and salts. You can also make a homemade ORS with clean water, salt, and
                                            sugar.</li>


                                        <li> <strong>   Avoid Solid Foods Initially: </strong> When you first start to recover, focus on rehydrating before
                                            gradually reintroducing bland foods like rice, bananas, and toast. Avoid spicy, fatty, or high-fiber
                                            foods until you are feeling better.</li>




                                        <li> <strong>    Good Hygiene:  </strong>  To prevent the spread of cholera, wash your hands thoroughly with soap and
                                            water after using the bathroom and before preparing or eating food. Ensure that food is cooked
                                            properly and water is treated (boiled or purified) if its source is questionable.
                                        </li>

                                        <li> <strong>   Follow Up with Healthcare Providers:</strong>   After initial treatment, follow up with your healthcare
                                            provider to ensure recovery and to address any lingering symptoms or concerns. Antibiotics
                                            may be prescribed in some cases to reduce the duration of the illness.</li>
                                    </ol>

                                </p>

                                <p>
                                    Taking these precautions and acting quickly is crucial for managing cholera and preventing
                                    serious complications.
                                </p>



                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* E. coli O157* */}
                {kit.EcoliO157 === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>E. coli O157</h2>


                            </div>

                            <div className="lastdivtext">

                                <p style={{ color: 'red' }}>
                                    Caution- in a small percentage of cases, E. coli O157 can cause severe symptoms and
                                    lead to serious complications. If this has been identified in your stool test you should seek the
                                    advice of your Doctor or Healthcare Practitioner.
                                </p>
                                <p> <strong>About <br /></strong>
                                    <strong> E. coli O157</strong> is a strain of the bacterium Escherichia coli that can cause severe gastrointestinal
                                    illness. It is classified as a shiga-toxin-producing E. coli (STEC) and is primarily transmitted
                                    through contaminated food or water, particularly undercooked ground beef, unpasteurized milk,
                                    and contaminated fruits and vegetables. Symptoms of infection typically begin 3 to 10 days after
                                    exposure and can include severe abdominal cramps, diarrhea (which may be bloody), nausea,
                                    and vomiting. In some cases, it can lead to serious complications such as hemolytic uremic
                                    syndrome (HUS), which can cause kidney failure. </p>

                                <p><strong>Advice<br /></strong>
                                    <strong>  Stay Hydrated:</strong> It is essential to maintain hydration, especially if you are experiencing
                                    diarrhoea. Drink plenty of fluids such as water, clear broths, and oral rehydration solutions to
                                    prevent dehydration. </p>

                                <p>
                                    <strong>  Avoid Certain Medications:  </strong>  Do not take over-the-counter anti-diarrheal medications, as they
                                    can prolong the illness and increase the risk of complications.
                                </p>

                                <p>
                                    <strong>   Rest:  </strong>  Ensure you get sufficient rest to allow your body to recover. Avoid strenuous activities until
                                    you feel better.
                                </p>

                                <p>
                                    <strong>   Seek Medical Attention:   </strong>   If you experience severe symptoms, such as bloody diarrhoea, high
                                    fever, or signs of dehydration (such as extreme thirst, dry mouth, or reduced urination), seek
                                    medical help immediately. HUS is a serious complication that requires prompt treatment.
                                </p>

                                <p>
                                    <strong>   Follow Up:  </strong>  After your initial recovery, follow up with your healthcare provider if you continue to
                                    have symptoms or if new symptoms develop. They may recommend further testing or
                                    monitoring.
                                </p>

                                <p>
                                    <strong>   Good Hygiene:  </strong> To prevent the spread of E. coli O157, wash your hands thoroughly with soap
                                    and water after using the bathroom and before preparing or eating food. Ensure that meat is
                                    cooked to a safe temperature, and wash fruits and vegetables thoroughly before consumption.
                                </p>

                                <p>
                                    Taking these measures can help manage symptoms and reduce the risk of complications from
                                    an E. coli O157 infection.
                                </p>



                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Enteroaggregative E. coli (EAEC) */}
                {kit.EnteroaggregativeEcoliEAEC === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Enteroaggregative E. coli (EAEC)</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    <strong> Enteroaggregative E. coli (EAEC) </strong>  is a pathotype of Escherichia coli known to cause
                                    gastrointestinal infections, particularly in young children and individuals in developing countries.
                                    It is characterised by its ability to adhere to the intestinal mucosa in a stacked-brick
                                    arrangement, which disrupts normal intestinal function. EAEC is often transmitted through
                                    contaminated food and water. Symptoms of EAEC infection can include persistent watery
                                    diarrhea, abdominal cramps, nausea, vomiting, and sometimes low-grade fever. The diarrhea
                                    can last for several days to weeks, leading to dehydration and malnutrition, particularly in
                                    vulnerable populations. </p>

                                <p><strong>Advice<br /></strong>
                                    <strong>  Stay Hydrated:</strong>: Hydration is crucial when dealing with diarrhea. Drink plenty of fluids, such as
                                    water, clear broths, and oral rehydration solutions (ORS) that contain electrolytes to prevent
                                    dehydration. </p>



                                <p>
                                    <strong>   Rest:  </strong>   Ensure you get adequate rest to help your body recover. Avoid strenuous activities and
                                    give your body time to heal.
                                </p>

                                <p>
                                    <strong>   Follow a Bland Diet:   </strong>    When you feel ready to eat, start with bland, easily digestible foods like
                                    bananas, rice, applesauce, and toast (the BRAT diet). Avoid fatty, spicy, or high-fiber foods, as
                                    these can irritate the digestive system.
                                </p>

                                <p>
                                    <strong>    Seek Medical Attention:   </strong>   If symptoms persist for more than a few days, worsen, or if you
                                    experience severe symptoms such as prolonged diarrhea or signs of dehydration (like extreme
                                    thirst, dry mouth, dizziness, or significantly reduced urination), consult a healthcare provider.
                                    Diagnosis may require stool tests, and in some cases, antibiotics may be necessary.
                                </p>

                                <p>
                                    <strong>   Good Hygiene:  </strong> To prevent spreading the infection, wash your hands thoroughly with soap and
                                    water after using the bathroom and before preparing or eating food. Ensure that food is cooked
                                    properly and avoid drinking untreated water.
                                </p>

                                <p>
                                    By taking these precautions and seeking appropriate care, you can help manage symptoms and
                                    prevent complications associated with an EAEC infection.

                                </p>



                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Enteropathogenic E. coli (EPEC)* */}
                {kit.EnteropathogenicEcoliEPEC === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Enteropathogenic E. coli (EPEC)</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Enteropathogenic Escherichia coli (EPEC) is a leading cause of infantile diarrhoea in developing
                                    countries. In industrialised countries, the frequency of these organisms has decreased, but they
                                    continue to be an important cause of diarrhoea. The presence of beneficial E. coli strains helps
                                    to prevent intestinal infections by competitively excluding pathogenic agents from binding to
                                    epithelial binding sites. </p>

                                <p><strong>Advice<br /></strong>
                                    The intake of prebiotics (FOS, GOS, inulin, and lactulose) and probiotics (fermented foods and
                                    dietary supplements) has been shown to beneficially modulate the gut microbiome composition
                                    so that it favours beneficial Lactobacillus and Bifidobacteria and reduces E. coli. We recommend
                                    following the microbiome rebalancing foods to help rebalance the microbiome which will make
                                    the gut environment unfavourable to this parasite. Antimicrobials may be something to consider
                                    later down the line as it could also disrupt beneficial bacteria. Berberine 400mg seems to be a
                                    promising antimicrobial for this particular parasite<a href='https://pubmed.ncbi.nlm.nih.gov/3549923/'>(https://pubmed.ncbi.nlm.nih.gov/3549923/).</a>
                                    In the meantime, you could suggest activated charcoal to minimise any toxins produced from
                                    the parasite <a href='(https://pubmed.ncbi.nlm.nih.gov/11307928/)'>(https://pubmed.ncbi.nlm.nih.gov/11307928/). </a></p>






                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Enterotoxigenic E. coli (ETEC) LT* */}
                {kit.EnterotoxigenicEcoliETEC === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Enterotoxigenic E. coli (ETEC) LT</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Enterotoxigenic Escherichia coli (ETEC) is a type of E. coli bacteria known to cause traveler's
                                    diarrhoea, especially in areas with limited access to clean water and sanitation. ETEC produces
                                    two types of toxins: heat-labile toxin (LT) and heat-stable toxin (ST), both of which lead to
                                    excessive water and electrolyte loss in the intestines. The LT toxin works similarly to cholera
                                    toxin, disrupting the gut lining and leading to symptoms such as watery diarrhea, stomach
                                    cramps, nausea, and sometimes low-grade fever. ETEC infections are generally self-limiting but
                                    can cause severe dehydration, particularly in young children and travelers. </p>

                                <p><strong>Advice<br /></strong>
                                    <strong> Hydration</strong>  Staying hydrated is essential since ETEC causes watery diarrhea, which can lead to
                                    dehydration. Drink plenty of fluids, and consider oral rehydration solutions to replace lost
                                    electrolytes. These can be found in pharmacies or made at home by mixing clean water, salt,
                                    and sugar.</p>

                                <p>
                                    <strong>  Rest:</strong> Give your body time to fight the infection by resting as much as possible, as activity can
                                    worsen dehydration and fatigue.
                                </p>

                                <p>
                                    <strong>   Eat a Bland Diet:</strong> Stick to easily digestible foods like bananas, rice, applesauce, and toast
                                    (BRAT diet), which can help ease digestive symptoms. Avoid fatty, spicy, and high-fibre foods
                                    that can irritate the gut.
                                </p>

                                <p>
                                    If symptoms persist beyond a few days, become severe, or include high fever or blood in stools,
                                    seek medical attention. In some cases, antibiotics may be prescribed to help shorten the illness,
                                    particularly for vulnerable groups like young children, the elderly, or immunocompromised
                                    individuals.

                                </p>






                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Shiga-Toxin-producing E. coli (STEC)* */}
                {kit.ShigatoxinproducingEcoliSTEC === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Shiga-Toxin-producing E. coli (STEC)</h2>


                            </div>

                            <div className="lastdivtext">

                                <p style={{ color: 'red' }}>
                                    Caution- in a small percentage of cases, Shiga-toxin-producing Escherichia coli can cause
                                    severe symptoms and lead to serious complications. If this has been identified in your stool test
                                    you should seek the advice of your Doctor or Healthcare Practitioner.
                                </p>
                                <p> <strong>About <br /></strong>
                                    Shiga-toxin-producing Escherichia coli (STEC) is a type of E. coli bacteria that produces Shiga
                                    toxin, a potent toxin that can damage the lining of the intestines and other organs. This strain is
                                    commonly associated with foodborne illnesses, often contracted by consuming undercooked
                                    meats, unpasteurized dairy, or contaminated produce. The symptoms of STEC infection typically
                                    include severe abdominal cramps, diarrhoea (often bloody), vomiting, and sometimes fever. In
                                    some cases, particularly in young children and the elderly, STEC can lead to a serious
                                    complication known as hemolytic uremic syndrome (HUS), which can cause kidney failure and
                                    requires immediate medical attention. </p>

                                <p><strong>Advice<br /></strong>
                                    Consult with your Doctor or Healthcare Practitioner</p>


                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}



                {/* Enteroinvasive E. coli (EIEC)* */}
                {kit.EnteroinvasiveEcoliEIEC === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Enteroinvasive E. coli (EIEC)</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Enteroinvasive Escherichia coli (EIEC) is a type of E. coli bacteria that invades and multiplies
                                    within the cells lining the intestines, causing an illness similar to dysentery. EIEC infections are
                                    typically acquired through contaminated food or water, and they closely mimic the symptoms
                                    caused by Shigella bacteria. Symptoms often include watery diarrhoea that can become bloody
                                    or mucus-filled, abdominal pain, fever, and cramping. The infection disrupts the intestinal wall,
                                    leading to inflammation and discomfort. While most cases resolve on their own, severe
                                    symptoms may require medical attention to prevent dehydration and manage discomfort.</p>

                                <p><strong>Advice<br /></strong>
                                    Due to disruption in the intestinal wall, it is recommended to speak with your Health Practitioner
                                    about rebalancing the gut microbiome after a EIEC infection. Taking a gut microbiome test and
                                    following the food scores will help this process

                                    <ol>
                                        <li><strong>   Hydrate Well:</strong>   Since diarrhea is a common symptom of EIEC, staying hydrated is crucial. Drink
                                            plenty of water and consider oral rehydration solutions (available at pharmacies or homemade
                                            with water, salt, and sugar) to restore lost fluids and electrolytes.</li>
                                        <li> <strong> Rest:</strong>   Give your body time to recover by resting. Physical exertion can worsen dehydration and
                                            delay recovery.</li>


                                        <li> <strong>   Stick to a Bland Diet:  </strong> Eat simple, easily digestible foods like bananas, rice, applesauce, and
                                            toast (BRAT diet) to avoid irritating the digestive system. Avoid spicy, fatty, and high-fiber foods,
                                            as they can make symptoms worse.</li>




                                        <li> <strong>   Avoid Anti-diarrheal Medications: </strong>  Over-the-counter anti-diarrheal medications are generally
                                            not recommended for EIEC, as they can slow down the body’s ability to expel the bacteria.
                                        </li>


                                    </ol>

                                </p>


                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Adenovirus* */}
                {kit.Adenovirus === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Adenovirus</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Adenovirus is a type of virus that can cause gastrointestinal infections, particularly in children. It
                                    is easily spread through contaminated food or water, and person-to-person contact. When it
                                    affects the gastrointestinal tract, symptoms can include diarrhoea, vomiting, abdominal pain,
                                    and fever. Adenovirus gastroenteritis is usually mild and self-limiting, often resolving within a few
                                    days. However, it can lead to dehydration, especially in young children and those with
                                    weakened immune systems, necessitating prompt attention to hydration and electrolyte
                                    balance. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong> Hydration is crucial, especially if you are experiencing diarrhea and
                                            vomiting. Drink plenty of fluids, including water, clear broths, and oral rehydration
                                            solutions to replace lost electrolytes.</li>
                                        <li> <strong> Rest:</strong> Allow your body to recover by getting plenty of rest. Avoid strenuous activities that
                                            could worsen your condition.</li>
                                        <li> <strong> Eat Bland Foods: </strong>When you feel ready to eat, start with bland, easy-to-digest foods
                                            such as bananas, rice, applesauce, and toast (BRAT diet). Avoid spicy, fatty, or high-fiber
                                            foods that may irritate your digestive system.</li>
                                        <li> <strong>   Monitor Symptoms:</strong> Keep track of your symptoms. If they worsen, or if you experience
                                            severe dehydration (indicated by symptoms like extreme thirst, dry mouth, decreased
                                            urination, or dizziness), seek medical attention.</li>
                                        <li> <strong>  Avoid Anti-diarrheal Medications: </strong>Over-the-counter medications that slow down
                                            diarrhea are generally not recommended for viral infections like adenovirus, as they can
                                            prolong the illness.</li>
                                    </ol>

                                </p>

                                <p>
                                    If symptoms persist for more than a few days, worsen, or if you have underlying health
                                    conditions, it’s advisable to consult a healthcare provider for further evaluation and
                                    management.

                                </p>


                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Astrovirus* */}
                {kit.Astrovirus === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Astrovirus</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Astrovirus is a viral pathogen that primarily affects the gastrointestinal tract, leading to
                                    gastroenteritis, especially in young children, the elderly, and immunocompromised individuals. It
                                    is transmitted through contaminated food or water and is highly contagious. Symptoms of an
                                    astrovirus infection typically include diarrhoea, vomiting, abdominal pain, and mild fever. While
                                    the illness is generally mild and self-limiting, it can lead to dehydration, particularly in vulnerable
                                    populations. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong>  Drink plenty of fluids, such as water, clear broths, and oral rehydration
                                            solutions to replenish lost electrolytes. This is crucial to prevent dehydration from
                                            diarrhea and vomiting.</li>
                                        <li> <strong> Rest:</strong>  Allow your body to recover by getting sufficient rest. Avoid strenuous activities to
                                            conserve energy.</li>


                                        <li> <strong>  Follow a Bland Diet: </strong>When you feel ready to eat, stick to bland foods that are easy on
                                            the stomach, such as bananas, rice, applesauce, and toast (the BRAT diet). Avoid spicy,
                                            fatty, and dairy-rich foods until you recover.</li>

                                        <li> <strong>   Monitor Symptoms:</strong> Keep an eye on your symptoms. If you experience severe
                                            dehydration (indicated by extreme thirst, dry mouth, dizziness, or significantly reduced
                                            urination), seek medical attention promptly.</li>


                                        <li> <strong>  Avoid Anti-diarrheal Medications: </strong> Over-the-counter medications that slow down
                                            diarrhea are typically not recommended for viral infections like astrovirus, as they can
                                            prolong the illness.</li>
                                    </ol>

                                </p>

                                <p>
                                    If symptoms persist for more than a few days or worsen, it's important to consult a healthcare
                                    provider for further evaluation and management.


                                </p>


                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Norovirus GI/GII* */}
                {kit.NorovirusGIGII === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Norovirus GI/GII</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Norovirus, particularly the GII strain, is a highly contagious virus that causes acute
                                    gastroenteritis, leading to inflammation of the stomach and intestines. It is commonly spread
                                    through contaminated food or water, as well as from person to person, often in crowded
                                    environments such as cruise ships, schools, and nursing homes. Symptoms of norovirus
                                    infection typically include sudden onset of diarrhea, vomiting, abdominal cramps, nausea, and
                                    sometimes low-grade fever. The illness is usually self-limiting but can lead to dehydration,
                                    especially in vulnerable populations like young children and the elderly. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong>  It’s crucial to maintain hydration since diarrhea and vomiting can lead to
                                            significant fluid loss. Drink plenty of fluids such as water, clear broth, or oral rehydration
                                            solutions to replenish lost electrolytes.</li>
                                        <li> <strong> Rest:</strong>   Resting helps your body recover from the infection. Avoid strenuous activities and give
                                            yourself time to heal.</li>


                                        <li> <strong>  Follow a Bland Diet: </strong> Once you feel able to eat, start with bland, easy-to-digest foods such as
                                            bananas, rice, applesauce, and toast (the BRAT diet). Avoid spicy, fatty, or rich foods until you
                                            recover.</li>

                                        <li> <strong>   Good Hygiene:</strong> Since norovirus is highly contagious, wash your hands thoroughly with soap
                                            and water, especially after using the bathroom and before eating or preparing food. Disinfect
                                            surfaces that may be contaminated.</li>


                                        <li> <strong>  Avoid Anti-diarrheal Medications: </strong> Over-the-counter medications that slow down diarrhea are
                                            generally not recommended for norovirus infections, as they may prolong the illness.</li>
                                    </ol>

                                </p>

                                <p>
                                    If symptoms persist for more than a few days, worsen, or if you experience severe dehydration
                                    (indicated by symptoms like extreme thirst, dry mouth, or decreased urination), seek medical
                                    attention promptly


                                </p>


                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Rotavirus A* */}
                {kit.RotavirusA === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Norovirus GI/GII</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Rotavirus A is a highly contagious virus that primarily affects the gastrointestinal tract, leading to
                                    acute gastroenteritis, particularly in infants and young children. It is transmitted through the
                                    fecal-oral route, often through contaminated hands, surfaces, or food and water. Symptoms of
                                    rotavirus infection typically include severe diarrhea, vomiting, abdominal cramps, fever, and
                                    dehydration. The diarrhea can be particularly profuse, leading to rapid fluid loss, which can be
                                    dangerous, especially in young children and infants. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong>  Maintaining hydration is crucial. Drink plenty of fluids such as water, clear
                                            broths, or oral rehydration solutions that contain electrolytes. This helps replenish lost fluids and
                                            prevents dehydration.</li>
                                        <li> <strong> Rest:</strong>  Ensure you get enough rest to help your body recover. Limit physical activity until you feel
                                            better.</li>


                                        <li> <strong>  Follow a Bland Diet: </strong> When you feel ready to eat, opt for bland, easily digestible foods such as
                                            bananas, rice, applesauce, and toast (the BRAT diet). Avoid dairy products, fatty foods, and
                                            sugary drinks, as they can worsen diarrhoea.</li>

                                        <li> <strong>   Good Hygiene:</strong>  To prevent spreading the virus, wash your hands thoroughly with soap and
                                            water after using the bathroom and before eating or preparing food. Disinfect surfaces that may
                                            be contaminated.</li>


                                        <li> <strong> Seek Medical Attention if Needed: </strong> If symptoms persist for more than a couple of days,
                                            worsen, or if you or your child show signs of severe dehydration (such as dry mouth, reduced
                                            urination, extreme thirst, or lethargy), seek medical help promptly.
                                        </li>
                                    </ol>

                                </p>




                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}


                {/* Sapovirus (I, II, IV, and V)* */}
                {kit.SapovirusIIIIVandV === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Sapovirus (I,II, IV, and V)</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Sapovirus, which includes several genotypes (I, II, IV, and V), is a type of virus that primarily
                                    causes gastroenteritis, particularly in children and occasionally in adults. It is transmitted via the
                                    fecal-oral route, often through contaminated food, water, or surfaces, and is known to cause
                                    outbreaks in settings such as schools, nursing homes, and cruise ships. Symptoms of sapovirus
                                    infection typically include diarrhea, vomiting, abdominal cramps, nausea, and low-grade fever.
                                    While the illness is usually mild and self-limiting, it can lead to dehydration, especially in young
                                    children and vulnerable populations. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong>  Hydration is crucial, especially if you are experiencing diarrhea and vomiting.
                                            Drink plenty of fluids, such as water, clear broths, and oral rehydration solutions that contain
                                            electrolytes to replenish lost fluids.</li>
                                        <li> <strong> Rest:</strong>   Ensure you get enough rest to support your body’s recovery. Avoid strenuous activities
                                            and prioritize sleep.</li>


                                        <li> <strong>  Follow a Bland Diet: </strong>  When you feel ready to eat, stick to bland foods that are easy on the
                                            stomach, such as bananas, rice, applesauce, and toast (the BRAT diet). Avoid dairy products,
                                            greasy foods, and sugary drinks, which can aggravate symptoms.</li>

                                        <li> <strong>   Good Hygiene:</strong>   Since sapovirus is highly contagious, wash your hands thoroughly with soap
                                            and water after using the bathroom and before eating or preparing food. Disinfect surfaces that
                                            may be contaminated to prevent further spread.</li>


                                        <li> <strong>  Avoid Anti-diarrheal Medications: </strong> Over-the-counter medications that slow down diarrhea are
                                            generally not recommended for viral gastroenteritis, as they can prolong the illness.
                                        </li>
                                    </ol>

                                </p>
                                <p>
                                    If symptoms persist for more than a few days, worsen, or if you experience signs of severe
                                    dehydration (such as extreme thirst, dry mouth, decreased urination, or dizziness), seek medical
                                    attention promptly
                                </p>




                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}


                {/* Cryptosporidium spp* */}
                {kit.Cryptosporidiumspp === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Cryptosporidium spp</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Cryptosporidium spp. are microscopic parasites that cause a gastrointestinal infection known as
                                    cryptosporidiosis. These parasites are typically transmitted through contaminated water, food, or
                                    surfaces, and they can be found in both recreational and drinking water. Infection with
                                    Cryptosporidium can lead to symptoms such as watery diarrhea, abdominal cramps, nausea,
                                    vomiting, and sometimes fever. Symptoms can vary in severity and may last from a few days to
                                    several weeks, particularly in immunocompromised individuals, where the infection can be more
                                    severe and prolonged. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong>  Hydration is essential, especially since diarrhea can lead to significant fluid
                                            loss. Drink plenty of fluids such as water, clear broths, and oral rehydration solutions containing
                                            electrolytes to prevent dehydration.</li>
                                        <li> <strong> Rest:</strong>  Give your body time to recover by getting sufficient rest. Avoid strenuous activities and
                                            take care of yourself.</li>


                                        <li> <strong>  Follow a Bland Diet: </strong> : When you feel able to eat, start with bland, easily digestible foods such as
                                            bananas, rice, applesauce, and toast (the BRAT diet). Avoid dairy products, greasy or spicy
                                            foods, and high-fiber foods, as they may irritate the digestive system.</li>

                                        <li> <strong>   Good Hygiene:</strong>   To prevent spreading the infection, wash your hands thoroughly with soap and
                                            water after using the bathroom and before preparing or eating food. Ensure that any surfaces
                                            that may be contaminated are properly disinfected</li>


                                        <li> <strong>   Seek Medical Attention if Needed: </strong> If symptoms persist for more than a few days, worsen, or if
                                            you experience signs of severe dehydration (such as extreme thirst, dry mouth, or significantly
                                            reduced urination), consult a healthcare provider. In some cases, especially in
                                            immunocompromised individuals, medication may be required to manage the infection
                                            effectively.
                                        </li>
                                    </ol>

                                </p>
                                <p>
                                    Taking preventive measures, such as ensuring good hygiene and avoiding drinking untreated
                                    water, can help reduce the risk of contracting Cryptosporidium spp. infections.

                                </p>




                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Cyclospora cayetanensis* */}
                {kit.Cyclosporacayetanensis === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Cryptosporidium spp</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Cryptosporidium spp. are microscopic parasites that cause a gastrointestinal infection known as
                                    cryptosporidiosis. These parasites are typically transmitted through contaminated water, food, or
                                    surfaces, and they can be found in both recreational and drinking water. Infection with
                                    Cryptosporidium can lead to symptoms such as watery diarrhea, abdominal cramps, nausea,
                                    vomiting, and sometimes fever. Symptoms can vary in severity and may last from a few days to
                                    several weeks, particularly in immunocompromised individuals, where the infection can be more
                                    severe and prolonged. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong>  Hydration is essential, especially since diarrhea can lead to significant fluid
                                            loss. Drink plenty of fluids such as water, clear broths, and oral rehydration solutions containing
                                            electrolytes to prevent dehydration.</li>
                                        <li> <strong> Rest:</strong>  Give your body time to recover by getting sufficient rest. Avoid strenuous activities and
                                            take care of yourself.</li>


                                        <li> <strong>  Follow a Bland Diet: </strong> : When you feel able to eat, start with bland, easily digestible foods such as
                                            bananas, rice, applesauce, and toast (the BRAT diet). Avoid dairy products, greasy or spicy
                                            foods, and high-fiber foods, as they may irritate the digestive system.</li>

                                        <li> <strong>   Good Hygiene:</strong>   To prevent spreading the infection, wash your hands thoroughly with soap and
                                            water after using the bathroom and before preparing or eating food. Ensure that any surfaces
                                            that may be contaminated are properly disinfected</li>


                                        <li> <strong>   Seek Medical Attention if Needed: </strong> If symptoms persist for more than a few days, worsen, or if
                                            you experience signs of severe dehydration (such as extreme thirst, dry mouth, or significantly
                                            reduced urination), consult a healthcare provider. In some cases, especially in
                                            immunocompromised individuals, medication may be required to manage the infection
                                            effectively.
                                        </li>
                                    </ol>

                                </p>
                                <p>
                                    Taking preventive measures, such as ensuring good hygiene and avoiding drinking untreated
                                    water, can help reduce the risk of contracting Cryptosporidium spp. infections.

                                </p>




                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Entamoeba histolytica* */}
                {kit.Entamoebahistolytica === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Cryptosporidium spp</h2>


                            </div>

                            <div className="lastdivtext">

                                <p style={{ color: 'red' }}>
                                    Caution- in a small percentage of cases, Entamoeba histolytica can cause severe symptoms
                                    and lead to serious complications. If this has been identified in your stool test you should seek
                                    the advice of your Doctor or Healthcare Practitioner.
                                </p>
                                <p> <strong>About <br /></strong>
                                    Entamoeba histolytica is a parasitic amoeba that causes an intestinal infection known as
                                    amoebiasis. It is primarily transmitted through the fecal-oral route, often via contaminated food
                                    or water, and is more prevalent in areas with poor sanitation. Symptoms of amoebiasis can
                                    range from mild to severe and may include watery diarrhea, abdominal pain, cramping, and, in
                                    some cases, dysentery, which is characterized by bloody diarrhea and mucus in the stool. In
                                    severe cases, the infection can lead to complications such as abscesses in the liver. </p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong>   Hydration is critical, especially if you are experiencing diarrhea. Drink plenty of
                                            fluids, such as water, clear broths, and oral rehydration solutions that contain electrolytes to
                                            prevent dehydration.</li>
                                        <li> <strong> Rest:</strong>   Make sure to get sufficient rest to allow your body to recover. Avoid strenuous activities
                                            until you feel better.</li>


                                        <li> <strong>  Follow a Bland Diet: </strong> : When you feel ready to eat, start with bland, easily digestible foods like
                                            bananas, rice, applesauce, and toast (the BRAT diet). Avoid fatty, spicy, and high-fiber foods
                                            that can irritate your digestive system.</li>



                                        <li> <strong>   Seek Medical Attention: </strong>  If symptoms persist for more than a couple of days, worsen, or if you
                                            experience severe symptoms such as bloody diarrhea or signs of dehydration (such as extreme
                                            thirst, dry mouth, or decreased urination), consult a healthcare provider. Diagnosis may require
                                            stool tests, and treatment typically involves specific antibiotics or antiparasitic medications.
                                        </li>


                                        <li> <strong>   Good Hygiene:</strong>  To prevent the spread of Entamoeba histolytica, wash your hands thoroughly
                                            with soap and water after using the bathroom and before preparing or consuming food. Ensure
                                            that food is cooked properly and water is treated or boiled if the source is uncertain.</li>
                                    </ol>

                                </p>
                                <p>
                                    Taking these precautions can help manage symptoms and prevent the spread of the infection

                                </p>




                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}




                {/* Giardia lamblia* */}
                {kit.Giardialamblia === "detected" && <>
                    <div className="lastdiv">
                        <div className="firstdivhead">
                            <img src="/logo2.png" alt="" style={{ marginTop: '10px' }} />
                            <img src="/uper.png" alt="" />



                        </div>
                        <div className="lastdivcenter">
                            <h1 style={{ color: "red" }}>Detected</h1>
                            <div className="texthead">

                                <h2>Giardia lamblia</h2>


                            </div>

                            <div className="lastdivtext">


                                <p> <strong>About <br /></strong>
                                    Giardia lamblia is a microscopic parasite that causes a gastrointestinal infection known as
                                    giardiasis. It is commonly transmitted through contaminated water, particularly in areas with poor
                                    sanitation, and can also spread through food or person-to-person contact. Symptoms of
                                    giardiasis typically include diarrhea (which may be foul-smelling and greasy), abdominal
                                    cramps, bloating, nausea, fatigue, and weight loss. Symptoms may appear one to three weeks
                                    after exposure and can last for several weeks to months if left untreated.</p>

                                <p><strong>Advice<br /></strong>
                                    <ol>
                                        <li><strong>  Stay Hydrated:</strong>  Maintaining hydration is crucial, especially if you are experiencing diarrhea.
                                            Drink plenty of fluids such as water, clear broths, and oral rehydration solutions that contain
                                            electrolytes to prevent dehydration.</li>
                                        <li> <strong> Rest:</strong>  Ensure you get enough rest to allow your body to recover. Avoid strenuous activities until
                                            you feel better</li>


                                        <li> <strong>  Follow a Bland Diet: </strong> When you feel ready to eat, start with bland, easy-to-digest foods such as
                                            bananas, rice, applesauce, and toast (the BRAT diet). Avoid fatty, spicy, or high-fibre foods, as
                                            they can aggravate gastrointestinal symptoms.</li>




                                        <li> <strong>   Seek Medical Attention if Needed: </strong>  If symptoms persist for more than a few days, worsen, or if you
                                            experience severe symptoms such as prolonged diarrhoea or signs of dehydration (such as
                                            extreme thirst, dry mouth, or significantly reduced urination), consult a healthcare provider.
                                            Diagnosis may require stool tests, and treatment typically involves antibiotics or antiparasitic
                                            medications to clear the infection.
                                        </li>

                                        <li> <strong>   Good Hygiene:</strong>    To reduce the risk of spreading the infection, wash your hands thoroughly with
                                            soap and water after using the bathroom and before preparing or eating food. Be cautious with
                                            drinking water, particularly when travelling; avoid drinking untreated water or ice made from it.</li>
                                    </ol>

                                </p>
                                <p>
                                    Taking these measures can help manage symptoms and prevent the spread of Giardia lamblia
                                    infections.

                                </p>




                                <p>Our treatment and advice suggestions have been devised by Nutritional Therapists and
                                    Functional Medicine Practitioners. Any treatment protocol should only be undertaken under the
                                    supervision of a Healthcare Practitioner.<br />
                                    For persistent and/or serious symptoms, it is recommended to speak to your Doctor.
                                </p>


                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ marginTop: '5mm' }} src='/imgggg.pngg' alt="" />
                            </div>

                        </div>




                        <div className="secenddivend">

                            <img src="/lower.png" alt="" />
                        </div>

                    </div>
                </>}






            </div >
        </div>
    </>)
}

export default Pararesults
