import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
// import 'antd/dist/reset.css'; // Import Ant Design styles



import Index from './pages';
import Desboard from './pages/desboard';
import Adminkit from './pages/adminkit';
import Adminreport from './pages/adminreport';
import Practitioner from './pages/Practitioner';
import Profile from './pages/Profile';
import Viewreport from './pages/viewreport';
import Viewreport2 from './pages/viewreport2';
import { useNavigate } from 'react-router-dom'
import Fskits from './pages/fskits';
import Fsprec from './pages/fsprec';
import Microkit from './pages/microkit';
import Mircoreport from './pages/mircoreport';
import Micropre from './pages/Micropre';
import DNAkit from './pages/DNAkit';
import DNAprec from './pages/DNAprec';
import DNAreport from './pages/DNAreport';
import Parasitologykit from './pages/Parasitologykit';
import Parasitologyreport from './pages/Parasitologyreport';
import Parasitologyprec from './pages/Parasitologyprec';
import Pracstate from './pages/pracstates';
import Orderlables from './pages/orderlables';
import Ackpage from './pages/ackpage';
import Bucket from './pages/dropresult'
import Shippingform from './pages/shippingform';
import Shipping from './pages/shipping'
import KitAnalysis from './pages/KitAnalysis';
import Kitregistration from './pages/kitregistration';
import Helpcenter from './pages/helpcenter'
import Tickets from './pages/tickets';
import Ticketsid from './pages/ticketsid';
import Webinar from './pages/Webinar';
import Webinardetails from './pages/Webinardetails';
import Meeting from './pages/meeting';
import Eventinfoform from './pages/eventinfoform';
import Eventinfo from './pages/eventinfo';
import Maps from './pages/maps';
import Dnamapform from './pages/Dnamapform';
import Praform from './pages/praform';
import Leads from './pages/Leads';
import Orderstates from './pages/orderstates';
import Pararesults from './pages/pararesults';
import Candidakit from './pages/Candidakit';
import Candidaprec from './pages/Candidaprec';
import Candidareport from './pages/Candidareport';
import Meetings from './pages/meetings';
import Meetingmeet from './pages/meetingmeet';
import Fsform from './pages/fsform';
import Fsformdata from './pages/foodsform';
import Meetingsusers from './pages/meetingsusers';
import Sendemail from './pages/sendemail';
import Fskitsmap from './pages/fskitsmap';
import Fsprecmap from './pages/fsprecmap';
import Adminreportmap from './pages/adminreportmap';
import Candidaform from './pages/candidaform';
import Candidaformdata from './pages/candidaformdata';


function ScrollToTop() {
  const { pathname } = useLocation();
  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const navigate = useNavigate()




  useEffect(() => {
    window.scrollTo(0, 0);

    if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("_id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfocheck", requestOptions)
        .then(response => response.text())
        .then(result => {
          if (result === "deactivated") {
            const allCookies = Cookies.get();
            for (const cookieName in allCookies) {
              Cookies.remove(cookieName);
            }
            navigate('/')
          }

        })
        .catch(error => console.log('error', error));
    }


  }, [pathname]);

  return null;
}



function App() {

  return (

    <div className="App">

      <BrowserRouter>
        <ScrollToTop />


        <Routes>

          <Route path='/' element={<Index />} />
          <Route path='/kitack' element={<Ackpage />} />
          <Route path='/shipping' element={<Shippingform />} />
          <Route path='/leads' element={<Leads />} />

          <Route path='/eventinfo' element={<Eventinfoform />} />
          <Route path='/bucket' element={<Bucket />} />
          <Route path='/dashboard' element={<Desboard />} />
          <Route path='/dashboard/analysis' element={<KitAnalysis />} />
          <Route path='/dashboard/webinar' element={<Webinar />} />
          <Route path='/dashboard/webinar/:id' element={<Webinardetails />} />
          <Route path='/dashboard/webinar/meeting/:id' element={<Meeting />} />
          <Route path='/dashboard/helpcenter' element={<Helpcenter />} />
          <Route path='/dashboard/tickets' element={<Tickets />} />
          <Route path='/dashboard/order-states' element={<Orderstates />} />
          <Route path='/dashboard/map' element={<Maps />} />
          <Route path='/dashboard/meet' element={<Meetings />} />
          <Route path='/dashboard/send-emails' element={<Sendemail />} />
          <Route path='/dashboard/meet-user' element={<Meetingsusers />} />

          <Route path='/dashboard/meet/:id' element={<Meetingmeet />} />

          <Route path='/dashboard/tickets/:id' element={<Ticketsid />} />
          <Route path='/dashboard/kitregistration' element={<Kitregistration />} />
          <Route path='/dashboard/para-report/:id' element={<Pararesults />} />
          <Route path='/dashboard/view-report/:id' element={<Viewreport />} />
          <Route path='/dashboard/view-report100/:id' element={<Viewreport2 />} />


        </Routes>

        <Routes>

          {/* fs/  */}
          <Route path='/dashboard/fs/kit' element={<Fskits />} />
          <Route path='/dashboard/fs/report' element={<Adminreport />} />
          <Route path='/dashboard/fs/practitioner' element={<Fsprec />} />
          <Route path='/foodsensitivityform' element={<Fsform />} />
          <Route path='/foodsensitivityform-data' element={<Fsformdata />} />

          {/* fs/  */}
          <Route path='/dashboard/fsmap/kit' element={<Fskitsmap/>} />
          <Route path='/dashboard/fsmap/report' element={<Adminreportmap />} />
          <Route path='/dashboard/fsmap/practitioner' element={<Fsprecmap />} />
      

          {/* micro/     */}
          <Route path='/dashboard/micro/kit' element={<Microkit />} />
          <Route path='/dashboard/micro/report' element={<Mircoreport />} />
          <Route path='/dashboard/micro/practitioner' element={<Micropre />} />



          {/* dna/     */}
          <Route path='/dashboard/dna/kit' element={<DNAkit />} />
          <Route path='/dnamap-form' element={<Dnamapform />} />

          <Route path='/Parasitology-Test-Form' element={<Praform />} />
          <Route path='/dashboard/dna/report' element={<DNAreport />} />
          <Route path='/dashboard/dna/practitioner' element={<DNAprec />} />

          {/* Parasitology */}
          <Route path='/dashboard/Parasitology/kit' element={<Parasitologykit />} />
          <Route path='/dashboard/Parasitology/report' element={<Parasitologyreport />} />
          <Route path='/dashboard/Parasitology/practitioner' element={<Parasitologyprec />} />


          {/* condida */}
          <Route path='/dashboard/candidaprofile/kit' element={<Candidakit />} />
          <Route path='/dashboard/candidaprofile/report' element={<Candidareport />} />
          <Route path='/dashboard/candidaprofile/practitioner' element={<Candidaprec />} />
          <Route path='/candidaform' element={<Candidaform />} />
          <Route path='/candidaform-data' element={<Candidaformdata />} />
        

          <Route path='/dashboard/kit' element={<Adminkit />} />
          <Route path='/dashboard/practitioner' element={<Practitioner />} />
          <Route path='/dashboard/practitionerstates' element={<Pracstate />} />
          <Route path='/dashboard/orders' element={<Orderlables />} />
          <Route path='/dashboard/shippingdata' element={<Shipping />} />
          <Route path='/dashboard/eventinfogdata' element={<Eventinfo />} />

          <Route path='/dashboard/profile' element={<Profile />} />




        </Routes>


      </BrowserRouter>

    </div>
  );
}

export default App;
