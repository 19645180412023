import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import Navbar from '../components/navbar';
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import '../css/shipping.css'
import { AutoComplete, message } from 'antd';
import axios from 'axios';

function Fsform() {


    const [btn, setbtn] = useState(true)

    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [KitCode, setKitCode] = useState("")
    const [DOB, setDOB] = useState("")
    const [sampledate, setsampledate] = useState("")


    const uploaddata = async (e) => {

        e.preventDefault()





        setbtn(false)
        const hide = message.loading("Action in progress", 0)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded2 = new URLSearchParams();
        urlencoded2.append("name", name);
        urlencoded2.append("email", email);
        urlencoded2.append("KitCode", KitCode);
        urlencoded2.append("DOB", DOB);
        urlencoded2.append("sampledate", sampledate);



        var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded2,
            redirect: 'follow'
        };


        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/fsformdata", requestOptions2)
            .then(response => response.text())
            .then(result => {

                setname("")
                setemail("")
                setKitCode("")
                setDOB("")
                setsampledate("")



                setTimeout(() => {
                    hide(); // Call hide to stop the loading message
                    message.success(result);
                }, 2000);

            })
            .catch(error => console.log('error', error));
        await setbtn(true)


    }



    return (<>


        <Navbar />
        <div className='deshboardmain' style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className='popup' style={{ height: 'fit-contant', backgroundColor: '#fdgh', position: 'relative', maxHeight: 'fit-content', zIndex: '0', left: "0", top: '0' }}>
                <form className='shippinfform' onSubmit={uploaddata}>
                    <h1 style={{ color: '#7152A1' }} >Food Sensitivity Form</h1>
                    <div className='lableee'>
                        <label>Name <span style={{ color: "red" }}>*</span></label>
                        <input onChange={(e) => setname(e.target.value)} value={name} required placeholder='Name' />
                    </div>

                    <div className='lableee'>
                        <label>Email <span style={{ color: "red" }}>*</span></label>
                        <input onChange={(e) => setemail(e.target.value)} value={email} required placeholder='Email' />
                    </div>




                    <div className='lableee'>
                        <label>Sample Date <span style={{ color: "red" }}>*</span></label>
                        <input type='date' onChange={(e) => setsampledate(e.target.value)} value={sampledate} required placeholder='Email' />
                    </div>

                    <div className='lableee testttttt'>
                        <label>Kitcode Number  <span style={{ color: "red" }}>*</span></label>
                        <input type='number' onInput={(e) => {
                            e.target.value = e.target.value.slice(0, 14); // Restrict to 14 digits
                        }} style={{ paddingLeft: '32px' }} onChange={(e) => setKitCode(e.target.value)} value={KitCode} required placeholder='Kit-Code' />
                    </div>

                    <div className='lableee'>
                        <label>Date Of Birth  <span style={{ color: "red" }}>*</span></label>
                        <input type='date' onChange={(e) => setDOB(e.target.value)} value={DOB} required placeholder='Phone' />
                    </div>







                    <button disabled={!btn} style={{ width: "100%" }}>SEND</button>
                </form>



            </div>
        </div>

    </>)
}

export default Fsform
