import React, { useState, useRef, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { AutoComplete, message } from 'antd'
import { AiFillDelete, AiOutlineClose } from 'react-icons/ai'
import { ImUpload2 } from 'react-icons/im'
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Cookies from 'js-cookie';


function DNAreport() {

  const [popup, setpopup] = useState(false)


  const [csvFile, setCSVFile] = useState([]);
  const fileInputRef = useRef(null);
  const [csvData, setCsvData] = useState([]);
  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const token2 = Cookies.get("Token2")


  const [kit, setkit] = useState([])
  const [practitioner, setpractitioner] = useState([])



  const sortkits = () => {
    if (kit.length !== 0) {
      const sortedKits = [...kit].sort((a, b) => {
        // Check if resultDate is available for both items
        if (a.resultDate && b.resultDate) {
          const dateA = new Date(a.resultDate.split('/').reverse().join('-'));
          const dateB = new Date(b.resultDate.split('/').reverse().join('-'));
          return dateB - dateA;
        }

        // Handle cases where resultDate might be missing
        if (!a.resultDate) return 1; // Place a at the end if it has no resultDate
        if (!b.resultDate) return -1; // Place b at the end if it has no resultDate

        return 0; // If both are missing resultDate, consider them equal
      });

      setkit(sortedKits);
    }
  }

  useEffect(() => {
    sortkits()
  }, [kit])



  const handleFileUpload = async (event) => {




    const files = event.target.files;

    console.log(event.target.files)
    // console.log(csvFile)
    setCSVFile(prevFiles => [...prevFiles, ...Array.from(files)]);





  };


  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setCSVFile(prevFiles => [...prevFiles, ...Array.from(droppedFiles)]);
    } else {
      alert('Please drop valid files.');
    }
  };




  const datauploaded = async (e) => {
    e.preventDefault()
    if (csvFile) {

      const hide = message.loading("Action in progress", 0)


      await csvFile.map(async (file, index) => {
        const ex = file.name.split('.')[1]
        const ex2 = file.name.split('.')[0]
        const newwkid = ex2
        const Kittt = newwkid.replace(/\D/g, '');

        const kitiddd = parseInt(Kittt)




        const imgRef = ref(imageDb, `dnamap/DNAMap result RD00${kitiddd}.${ex}`)
        await uploadBytes(imgRef, file)
        const cvUrl = await getDownloadURL(imgRef);



        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded2 = new URLSearchParams();
        urlencoded2.append("result", cvUrl);
        urlencoded2.append("id", kitiddd);

        var requestOptions2 = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded2,
          redirect: 'follow'
        };


        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/uploadresultother", requestOptions2)
          .then(response => response.json())
          .then(result => setkit(result))
          .catch(error => console.log('error', error));

      })





      await setpopup(false)
      await setCSVFile([])


      await setTimeout(() => {
        hide(); // Call hide to stop the loading message
        message.success("Action completed successfully");
      }, 2000);

    }

    else { message.error("please upload a file first") }
  }


  useEffect(() => {


    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }







  }, [])


  var sno1 = 1
  var sno2 = 1






  const [searchTerm, setSearchTerm] = useState('');
  var options = [];

  const dooption = () => {



    practitioner.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      options.push({ value: value.name })
      options.push({ value: value.email })


    })

    kit.map((value, index) => {

      if (value.otherresults !== "nil" && value.Kittype === "dm") {
        options.push({ value: `RD00${value.kitid}` })
        // options.push({ value: value.patientName })
        // options.push({ value: value.resultDate })
        // options.push({ value: value.SampleDate })
        // options.push({ value: value.DOB })

      }

    })






  }



  const handleInputChange = async (e) => {
    e.preventDefault()

    const intttt = (searchTerm)

    const filteredSuggestionspra = practitioner.filter((item) =>

      item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



    );




    const Kittt = searchTerm.replace(/\D/g, '');

    const intttt2 = parseInt(Kittt)

    // const  = parseInt(searchTerm)



    const filteredSuggestions = kit.filter((item) => {
      const resullll = item.result;
      if (item.otherresults !== "nil") {
        return (
          item.kitid === intttt2
          || filteredSuggestionspra.map((itemmm) => itemmm._id).includes(item.assignedto.toLowerCase())




        );
      }


    });

    // const intttt = parseInt(searchTerm)

    // // Filter the Kit array based on the user's input
    // const filteredSuggestions = kit.filter((item) =>
    //   item.kitid === intttt
    // );


    // console.log(filteredSuggestions)
    setkit(filteredSuggestions)
    setsearchdone(true)

  };
  const [searchdone, setsearchdone] = useState(false)

  const clearsearch = async () => {
    const hide = message.loading("Action in progress", 0)

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }



    await setsearchdone(false)
    setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  }


  return (
    <div>

      <Navbar />

      <div className='deshboardmain'>
        <Sidemanu />
        <div className='profilemainbody'>

          <div className='header'>
            <h1>DNAMap Reports</h1>
            {dooption()}
            {searchdone === false && <>
              <form onSubmit={handleInputChange}>
                <AutoComplete
                  type="number"
                  style={{ width: 200 }}
                  options={options}
                  placeholder="Email/Name/Patient Name/Sample Date/upload/practitioner/NamePractitioner Email "
                  filterOption={(inputValue, options) =>
                    options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    //  console.log(kitss) 
                  }
                  onChange={(inputValue) => setSearchTerm(inputValue)}
                />
                <button>Search</button>
              </form>
            </>}
            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
            </>}
          </div>





          {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
            <div className='addbutton' style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} ><h1></h1><button onClick={() => setpopup(true)}><ImUpload2 style={{ width: '20px', height: '20px' }} />Upload Result</button></div>
          </>}

          {popup === true && <>
            <div onClick={() => {
              setpopup(false)
              setCSVFile([])
            }
            } className='popupbg'></div>
            <div className='popup'>



              <div className="file-drop-zone"


                onDrop={handleFileDrop}
                onDragOver={(e) => e.preventDefault()}

              >

                <label className="custom-file-upload" >

                  <div className='clickablediv'>


                    <ImUpload2 style={{ width: '100px', height: '100px' }} />
                    <h4>Click or drag file to this area to upload</h4>


                  </div>
                  <input
                    multiple="off"
                    ref={fileInputRef}
                    className='inputbuttontouploadfile'
                    type="file"
                    accept=".pdf"
                    onChange={handleFileUpload}
                  />

                </label>

              </div>

              {csvFile.length > 0 && (
                <div>
                  <h3>Uploaded Files:</h3>
                  <ul>
                    {csvFile.map((file, index) => (
                      <div className="filename" key={index}>
                        <p>{file.name}</p>
                        <AiFillDelete
                          onClick={() => {

                            const updatedFiles = csvFile.filter((_, i) => i !== index);
                            setCSVFile(updatedFiles);

                          }}
                          className="hovar"
                          style={{ width: '30px', height: '30px', color: 'red' }}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
              )}







              <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }} onSubmit={datauploaded}>
                {/* <input placeholder="Enter Result's Kit ID" required onChange={(e) => { setkitiddd(e.target.value) }} value={kitiddd} /> */}


                <div className='bownpopupbutton' style={{ width: '100%' }}>
                  <button onClick={() => {
                    setpopup(false)
                    setCSVFile([])
                  }
                  } style={{ border: '1px solid red', color: 'black' }} >cancel</button>
                  <button type='submit' style={{ backgroundColor: '#4180b7' }}>Upload</button>
                </div>

              </form>







            </div>
          </>}














          {kit.length !== 0 && <>



            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th>S NO.</th>

                  <th>Kit ID</th>



              {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>    <th>Practitioner Name</th></>}
              {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>     <th>Practitioner Email</th></>}
                  {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>     <th>Patient Name</th></>}

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>


                {kit.map((item, index) =>

                  <>
                    {(item.otherresults !== "nil" && item.Kittype === "dm") && <>










                      <tr>
                        <td>{sno1++}</td>

                        <td>RD00{item.kitid}</td>






                        {practitioner.map((item2, index) => (<>

                          {item2._id === item.assignedto && <>
                            {/* {downloadCSV(cout, item.DOB, item.patientName, item.kitid, item.SampleDate, item.resultDate, item2.name, item2.email, item.result)} */}
                            <td>{item2.name}</td>

                            <td>{item2.email}</td>
                          </>}</>))}







                        {/* <td className='assignbuttom' ><BsCloudDownload onClick={async () => {


              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

              var urlencoded = new URLSearchParams();
              urlencoded.append("_id", item._id);

              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
              };





              try {
                const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions);
                const result = await response.json();
                setkit2(result);
                setResult2(result.result);

                // Wait for 2 seconds
                await new Promise(resolve => setTimeout(resolve, 500));

                // Call the DownloadPDF function after waiting
                DownloadPDF(item.patientName);
              } catch (error) {
                console.log('error', error);
              }











            }} className='hovar' style={{ width: '30px', height: '30px', color: '#4180b7' }} /></td> */}


                        {/* {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
              {item.ackresult ? (<><td>Result Already Sent</td></>) : (<>
                <td  ><AiOutlineMail onClick={async () => {



                  var myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                  var urlencoded = new URLSearchParams();
                  urlencoded.append("_id", item._id);

                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: 'follow'
                  };






                  const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresults210", requestOptions);
                  const result = await response.json();
                  await setkit(result);












                }} className='hovar' style={{ width: '30px', height: '30px', color: '#4180b7' }} /></td>

              </>)}
            </>} */}
                        {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>
                          <td  >
                            {item.patientName}
                          </td>
                        </>}
                        <td style={{ display: 'flex', justifyContent: 'space-around' }}>

                          {item.ackresult ? (<> <button className='button' style={{ backgroundColor: '#fff', border: "1px solid #4180B7", color: "#4180B7" }} onClick={() => { message.info("The result has already been sent.") }} >Already Sent</button></>) : (<>
                            <button className='button' onClick={async () => {
                              const hide = message.loading("Action in progress", 0)
                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", item._id);

                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };






                              const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresultsothers", requestOptions);
                              const result = await response.json();
                              await setkit(result);

                              await setTimeout(() => {
                                hide(); // Call hide to stop the loading message
                                message.success("Action completed successfully");
                              }, 2000);

                            }}>Send Result</button>
                          </>)}


                          <button
                            className='button'
                            style={{ backgroundColor: '#6E4E9F' }}
                            onClick={() => {
                              const link = document.createElement('a');
                              link.href = item.otherresults;
                              link.setAttribute('download', `DNAMap result RD00${item.kitid}.pdf`);
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                          >
                            Download
                          </button>

                        </td>
                      </tr>




                    </>}






                  </>
                )}



              </tbody>
            </table>



            {/* <a onClick={downloadCSV} style={{ display: 'flex', textAlign: 'right', justifyContent: 'end', width: '100%' }} href='/'>Download this data as CSV</a > */}

            {/* <div style={{ display: 'flex', justifyContent: "flex-end" }}>
              <CSVLink data={data} headers={headers} filename={"yourgutmap-food-sensitivity Report.csv"}>
                <button className='downloadbutton' > <FaDownload style={{ width: '20px', height: '20px' }} /> Download this data as CSV</button >
              </CSVLink>

            </div> */}
          </>}









        </div>


      </div>

    </div>
  )
}

export default DNAreport