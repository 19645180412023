import React, { useState, useRef, useEffect } from 'react'
import Navbar from '../components/navbar';
import "../css/adminreport.css"
import Sidemanu from '../components/sidemanu';
import "../css/adminkit.css"
import { ImUpload2, ImUserTie } from "react-icons/im"
import { BsCalendar2DateFill, BsCloudDownload } from "react-icons/bs"
import { AiFillDelete, AiOutlineClose, AiOutlineInteraction } from "react-icons/ai"
import Papa from 'papaparse';
import { AutoComplete, Dropdown, message } from 'antd';
import { CSVLink } from 'react-csv';
import { FaDownload, FaRegUser } from "react-icons/fa"
import html2pdf from 'html2pdf.js';
import Cookies from 'js-cookie';
import { AiOutlineMail } from "react-icons/ai"
import { GiFirstAidKit } from 'react-icons/gi';
import { IoIosArrowDropdown } from 'react-icons/io';
import { MdOutlinePendingActions, MdOutlineUpdate, MdOutlineViewDay } from 'react-icons/md';
import { TbUserShare } from 'react-icons/tb';



function Adminreportmap() {


  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const token2 = Cookies.get("Token2")


  const [popup, setpopup] = useState(false)

  const fileInputRef = useRef(null);

  const [csvFile, setCSVFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [kit, setkit] = useState([])
  const [practitioner, setpractitioner] = useState([])



  const [kit2, setkit2] = useState([]);

  const [Result2, setResult2] = useState([]);
  const [Result222, setResult222] = useState([]);




  const sortkits = () => {
    if (kit.length !== 0) {
      const sortedKits = [...kit].sort((a, b) => {
        // Check if resultDate is available for both items
        if (a.resultDate && b.resultDate) {
          const dateA = new Date(a.resultDate.split('/').reverse().join('-'));
          const dateB = new Date(b.resultDate.split('/').reverse().join('-'));
          return dateB - dateA;
        }

        // Handle cases where resultDate might be missing
        if (!a.resultDate) return 1; // Place a at the end if it has no resultDate
        if (!b.resultDate) return -1; // Place b at the end if it has no resultDate

        return 0; // If both are missing resultDate, consider them equal
      });

      setkit(sortedKits);
    }
  }

  useEffect(() => {
    sortkits()
  }, [kit])









  useEffect(() => {


    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }







  }, [])


  var cout = 0;
  var cout2 = 0;



  const [searchTerm, setSearchTerm] = useState('');
  var options = [];

  const dooption = () => {



    practitioner.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      options.push({ value: value.name })
      options.push({ value: value.email })


    })

    kit.map((value, index) => {

      if (value.result && value.result.length !== 0) {
        options.push({ value: value.kitid })
        options.push({ value: value.patientName })
        options.push({ value: value.resultDate })
        options.push({ value: value.SampleDate })
        options.push({ value: value.DOB })

      }

    })






  }



  const handleInputChange = async (e) => {
    e.preventDefault()

    const intttt = (searchTerm)

    const filteredSuggestionspra = practitioner.filter((item) =>

      item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



    );




    const intttt2 = parseInt(searchTerm)

    const filteredSuggestions = kit.filter((item) => {
      const resullll = item.result;
      if (resullll && resullll.length !== 0) {
        return (
          item.DOB.toLowerCase().includes(intttt.toLowerCase())
          || item.patientName.toLowerCase().includes(intttt.toLowerCase())
          || item.SampleDate.toLowerCase().includes(intttt.toLowerCase())
          || item.resultDate.toLowerCase().includes(intttt.toLowerCase())
          || item.kitid === intttt2
          || filteredSuggestionspra.map((itemmm) => itemmm._id).includes(item.assignedto.toLowerCase())




        );
      }


    });


    // const intttt = parseInt(searchTerm)

    // // Filter the Kit array based on the user's input
    // const filteredSuggestions = kit.filter((item) =>
    //   item.kitid === intttt
    // );


    // console.log(filteredSuggestions)
    setkit(filteredSuggestions)
    setsearchdone(true)

  };
  const [searchdone, setsearchdone] = useState(false)

  const clearsearch = async () => {
    const hide = message.loading("Action in progress", 0)

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }



    await setsearchdone(false)
    setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  }


  const [userinfo, setuserinfo] = useState([])
  const [popupdetails, setpopupdetails] = useState(false)









  const items = [
    {
      key: '1',
      label: (
        <a href={"/dashboard/view-report/" + userinfo._id} target="_blank" rel="noopener noreferrer" onClick={async () => {






        }}>
          View result
        </a>
      ),
      icon: <MdOutlineViewDay style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
   

    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={async () => {
          const hide = message.loading("Action in progress", 0)

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("_id", userinfo._id);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };






          const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresults210map", requestOptions);
          const result = await response.json();
          await setkit(result);

          await setpopupdetails(false)






          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);
        }}>
          Send Results
        </a>
      ),
      disabled: userinfo.ackresult,

      icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

  ];




  var sno1 = 1
  var sno2 = 1



  return (<>
    {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && <>
      <Navbar />
      <div className='deshboardmain'>
        <Sidemanu />
        <div className='adminkitmainbody'>
          <div className='header'>
            <h1>FoodSensitivityMap Reports</h1>
            {dooption()}
            {searchdone === false && <>
              <form onSubmit={handleInputChange}>
                <AutoComplete
                  type="number"
                  style={{ width: 200 }}
                  options={options}
                  placeholder="Email/Name/Patient Name/Sample Date/upload/practitioner/NamePractitioner Email "
                  filterOption={(inputValue, options) =>
                    options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    //  console.log(kitss) 
                  }
                  onChange={(inputValue) => setSearchTerm(inputValue)}
                />
                <button>Search</button>
              </form>
            </>}
            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
            </>}
          </div>

          {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>



       
          </>}
          {popupdetails === true && <>
            <div onClick={() => {
              setpopupdetails(false)
              setCSVFile(null)
            }
            } className='popupbg'></div>
            <div className='popup'>



              <div className='header' >
                <h2>Kits Result Details</h2>
              </div>
              <div className='kitdetailsmain' >




                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <GiFirstAidKit style={{ width: '20px', height: '20px', margin: "0 10px" }} /> KIT ID</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  T4-{userinfo.kitid}-YGM </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    {practitioner.map((item2, index) => (<>

                      {item2._id === userinfo.assignedto && <>

                        <td>{item2.name}</td>

                        {/* <td>{item2.email}</td> */}
                      </>}</>))}


                  </h3>
                </div>
                <div className='kitdetails'>

                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbUserShare style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    {practitioner.map((item2, index) => (<>

                      {item2._id === userinfo.assignedto && <>




                        <td>{item2.email}</td>
                      </>}</>))}



                  </h3>
                </div>

                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <ImUserTie style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Patient Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.patientName} </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <BsCalendar2DateFill style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Date of birth</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.DOB} </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlinePendingActions style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Sample Date</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.SampleDate} </h3>
                </div>
                <div className='kitdetails'>
                  <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlineUpdate style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Result upload on</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {userinfo.resultDate} </h3>
                </div>
                <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <AiOutlineInteraction style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                  <Dropdown menu={{ items: items }} placement="bottomRight" arrow>
                    <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                  </Dropdown>


                </h3></div>





              </div>




              <div className='bownpopupbutton'>
                <button onClick={() => {
                  setpopupdetails(false)

                }
                } style={{ border: '1px solid red', color: 'black' }} >cancel</button>












              </div>

            </div>
          </>}











          {kit.length !== 0 && <>



            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th>S NO.</th>

                  <th>Kit ID</th>

                  <th>Sample Date</th>

                 {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>       <th>Practitioner Name</th></>}
                 {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>        <th>Practitioner Email</th></>}
                  {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>     <th>Patient Name</th></>}

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>


                {kit.map((item, index) =>

                  <>
                    {(item.result.length !== 0 && item.Kittype === "map") && <>










                      <tr>
                        <td>{sno1++}</td>

                        <td>T4-{item.kitid}-YGM</td>


                        <td>{item.SampleDate}</td>



                        {practitioner.map((item2, index) => (<>

                          {item2._id === item.assignedto && <>

                            <td>{item2.name}</td>

                            <td>{item2.email}</td>
                          </>}</>))}







                        {/* <td className='assignbuttom' ><BsCloudDownload onClick={async () => {


                          var myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                          var urlencoded = new URLSearchParams();
                          urlencoded.append("_id", item._id);

                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                          };





                          try {
                            const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions);
                            const result = await response.json();
                            setkit2(result);
                            setResult2(result.result);

                            // Wait for 2 seconds
                            await new Promise(resolve => setTimeout(resolve, 500));

                            // Call the DownloadPDF function after waiting
                            DownloadPDF(item.patientName);
                          } catch (error) {
                            console.log('error', error);
                          }











                        }} className='hovar' style={{ width: '30px', height: '30px', color: '#4180b7' }} /></td> */}


                        {/* {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                          {item.ackresult ? (<><td>Result Already Sent</td></>) : (<>
                            <td  ><AiOutlineMail onClick={async () => {



                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", item._id);

                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };






                              const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sentresults210", requestOptions);
                              const result = await response.json();
                              await setkit(result);












                            }} className='hovar' style={{ width: '30px', height: '30px', color: '#4180b7' }} /></td>

                          </>)}
                        </>} */}
                        {token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg" && <>
                          <td  >
                            {item.patientName}
                          </td>
                        </>}
                        <td  >
                          <button className='button' onClick={() => {
                            setpopupdetails(true)
                            setuserinfo(item)
                          }}>Detail</button>
                        </td>
                      </tr>




                    </>}






                  </>
                )}



              </tbody>
            </table>




          </>}





          {kit.length === 0 && <>

            <img alt='' src='/empty.gif' width={"40%"} />
          </>}



        </div>


      </div>








    </>} </>)
}









export default Adminreportmap