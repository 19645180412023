import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import "../css/viewreport.css"
// import { image } from 'html2canvas/dist/types/css/types/image';
// import { CSVLink } from "react-csv"
import html2pdf from 'html2pdf.js';


function Viewreport() {

  const { id } = useParams();
  const [isPdfMode, setIsPdfMode] = useState(false);
  const [kit, setkit] = useState([]);
  const [Result, setResult] = useState([]);

  const contentRef = useRef();
  const contentRef2 = useRef();



  const handleDownloadPDF = async () => {
    await setIsPdfMode(true)


    const content = await document.getElementById('ali'); // Replace with the ID of the content you want to export


    const opt = {
      margin: 0,
      filename: 'my_webpage.pdf',
      image: { type: 'jpeg', quality: 2 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const seee = html2pdf().from(content).set(opt).outputPdf();



    seee.save("YourGutMap IgG FS 210 - " + kit.patientName + ".pdf")
    await setIsPdfMode(false)


  };








  const getdata = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions)
      .then(response => response.json())
      .then(result => {
        setkit(result)
        setResult(result.result)

        const newresult = result.result
        if (newresult.length > 240) {

          // alert("asef")
          const url = `https://fst-report.yourgutmap.co.uk/${result._id}`
          redirect(url)
          // window.location.href = url;
        } else {
          // alert("no no")
        }

      })
      .catch(error => console.log('error', error));


  }


  const redirect = (url) => {
    window.location.href = url;
  }


  useEffect(() => {


    getdata()
    // sumofresult()

  }, [])


  const sumofresult = () => {

    var sum = 0;
    Result.map((value, index) => {
      if (value <= 0) { sum = sum + 0 }
      else { sum = sum + Math.round(value) }
    })
    // console.log(sum
    return sum;
  }

  const sum2 = () => {

    var sum = 0;
    Result.map((value, index) => {
      if (value <= 0) { sum = sum + 0 }
      else { sum = sum + Math.round(value) }
    })


    var sum2 = ''

    const xMin = 100;
    const xMax = 400;
    const valueMin = -40;
    const valueMax = 40;
    var refValue = 0

    if (sum <= 100) { refValue = -45 } else if (sum >= 400) { refValue = 45 } else { refValue = (sum - xMin) / (xMax - xMin) * (valueMax - valueMin) + valueMin; }




    sum2 = (refValue.toString() + "%")
    return sum2;
  }












  var valuemorethen10 = []
  var value5to5 = []





  const sorting = () => {


    value5to5.sort(function (a, b) {
      return b.value - a.value; // Compare in descending order
    });



    valuemorethen10.sort(function (a, b) {
      return b.value - a.value; // Compare in descending order
    });


  }

  return (<>

    {kit.length !== 0 && <>

      <div>
        <button className='downloadbuttonviewreport' onClick={handleDownloadPDF}>download</button>
      </div>
      <div id='ali' >

        {/* page 1 */}
        <div ref={contentRef} className={isPdfMode ? 'pdfpage' : 'screen-display'} >
          <img alt='' src='/g1.png' className='uperimg' />
          <div className='pageecontant'>

            <img alt='' src='/logo.png' className='logoimg' />

          </div>
          <img alt='' src='/g2.png' width={"100%"} className='uperimg' />
        </div>

        {/* page 2 */}
        <div ref={contentRef2} className={isPdfMode ? 'pdfpage' : 'screen-display'} >
          <img alt='' src='/g1.png' className='uperimg' />
          <div className='pageecontant'>

            <img alt='' src='/logo.png' className='logoimg2' />

            <div className='info' style={{ fontSize: '0.9em' }}>
              <div style={{ display: 'flex', gap: "10px" }}>  <h2 style={{ color: '#6E4E9F' }}> Patient Name : </h2> <h2 style={{ color: '#6799C6' }}> {" "} {kit.patientName}</h2> </div>
              <div style={{ display: 'flex', gap: "10px" }}>    <h2 style={{ color: '#6E4E9F' }}>Sample ID : </h2> <h2 style={{ color: '#6799C6' }}>{kit.kitid}</h2> </div>
              <div style={{ display: 'flex', gap: "10px" }}>   <h2 style={{ color: '#6E4E9F' }}>Sample Date : </h2> <h2 style={{ color: '#6799C6' }}>{kit.SampleDate}</h2> </div>
            </div>

            <div className='info' style={{ backgroundColor: '#6E4E9F' }} >
              <h3 style={{ color: 'white' }}>Total IgG Immune Load </h3>
              <p style={{ color: 'white' }} >Your total IgG reactivity shows the current immune load your body is experiencing with your current
                diet. Research shows by following your IgG guided dietary changes, total immune load can be
                reduced.</p>

              <div className='page2leveldiv' >

                <div className='page2leveldivinner'>

                  <div className='raisedimmuneload' style={{ color: '#6E4E9F' }}> <div className='triangle'></div>
                    raised<br />
                    immune<br />
                    load<br />
                  </div>
                  <div className='immuneload' style={{ color: '#6E4E9F', left: sum2() }}>
                    Your immune load
                    <div className='plus-sign ' >


                    </div>
                    <div className='plus-signbefore'><h4>{sumofresult()}</h4></div>

                    <div className='triangle2'></div>

                  </div>
                  <div className='highimmuneload' style={{ color: '#6E4E9F' }}> <div className='triangle'></div>
                    high<br />
                    immune<br />
                    load<br />
                  </div>

                </div>

              </div>
            </div>


            <div className='info' style={{ color: '#6E4E9F', fontSize: '0.9em' }}>
              <p>Your food specific IgG antibody diet guide shows IgG reactions and cross reactions to foods and drinks.
                <br />   <br />  This information can be used by a qualified healthcare practitioner to adjust your diet. Any changes in diet including the
                removal of foods should be overseen by a qualified professional, to prevent nutrient deficiencies.
                <br />   <br />  Please note: a food specific IgG antibody test does not analyse classical allergies, where IgE antibodies are involved. Food
                specific IgG antibody testing cannot diagnose conditions such as Coeliac Disease, or Lactose Intolerance. Any pre existing
                allergies or negative reactions to foods should be discussed with your health practitioner.
                YourGutMap defines Food Sensitivity as a food specific IgG antibody reaction. This is not a diagnostic test</p>
            </div>

          </div>
          <img alt='' src='/g2.png' className='uperimg' />
        </div>


        {/* page 3 */}
        <div ref={contentRef} className='pdfpage222'>
          <img alt='' src='/g1.png' className='uperimg' style={{ height: "180px" }} />
          <div className='pageecontant2'>

            <div className='info' >
              <h1 style={{ color: '#6799C6' }}>{kit.patientName}</h1>
              <h3 style={{ color: '#6E4E9F' }}>YourGutMap IgG Food Sensitivity Screen</h3>


              <div className={isPdfMode ? "indicatorp" : "indicator"}>

                <div style={{ backgroundColor: '#d2393f' }}>Elevated {'>'} 10 IgG AU/ml</div>
                <div style={{ backgroundColor: '#ecca99' }}>Borderline 5 - 9 IgG AU/ml</div>
                <div style={{ backgroundColor: '#4280b8' }}>Normal {'<'} 5 IgG AU/ml</div>

              </div>


              <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>


                {Result.map((item, index) => {

                  var value
                  var foodname = "";

                  if (index === 0) { foodname = "Barley" }
                  if (index === 1) { foodname = "Gluten (Gliadin)" }
                  if (index === 2) { foodname = "Rye " }
                  if (index === 3) { foodname = "Wheat " }
                  if (index === 4) { foodname = "Spelt " }
                  if (index === 5) { foodname = "Brown Rice" }
                  if (index === 6) { foodname = "Buckwheat " }
                  if (index === 7) { foodname = "Oat" }

                  if (index === 8) { foodname = "White Rice" }
                  if (index === 9) { foodname = "Quinoa " }
                  if (index === 10) { foodname = "Apple" }
                  if (index === 11) { foodname = "Apricot" }
                  if (index === 12) { foodname = "Banana" }
                  if (index === 13) { foodname = "Blackberry" }
                  if (index === 14) { foodname = "Blueberry" }
                  if (index === 15) { foodname = "Black Olive " }
                  if (index === 16) { foodname = "Cantaloupe Melon" }
                  if (index === 17) { foodname = "Cherry" }
                  if (index === 18) { foodname = "Cranberry" }
                  if (index === 19) { foodname = "Date" }
                  if (index === 20) { foodname = "Fig" }
                  if (index === 21) { foodname = "Grape Red" }
                  if (index === 22) { foodname = "Grape White" }
                  if (index === 23) { foodname = "Grapefruit" }
                  if (index === 24) { foodname = "Honeydew Melon" }
                  if (index === 25) { foodname = "Lemon" }
                  if (index === 26) { foodname = "Lime" }
                  if (index === 27) { foodname = "Lychee" }
                  if (index === 28) { foodname = "Mango" }
                  if (index === 29) { foodname = "Nectarine " }
                  if (index === 30) { foodname = "Green Olive" }
                  if (index === 31) { foodname = "Orange" }
                  if (index === 32) { foodname = "Peach" }
                  if (index === 33) { foodname = "Pear" }
                  if (index === 34) { foodname = "Persian Melon " }
                  if (index === 35) { foodname = "Pineapple" }
                  if (index === 36) { foodname = "Raisins" }
                  if (index === 37) { foodname = "Pomegranate" }
                  if (index === 38) { foodname = "Raspberry" }
                  if (index === 39) { foodname = "Strawberry" }
                  if (index === 40) { foodname = "Sultana" }
                  if (index === 41) { foodname = "Tangerine " }
                  if (index === 42) { foodname = "Watermelon" }
                  if (index === 43) { foodname = "Asparagus" }
                  if (index === 44) { foodname = "Avocado" }
                  if (index === 45) { foodname = "Beetroot " }
                  if (index === 46) { foodname = "Bok Choy " }
                  if (index === 47) { foodname = "Broccoli" }
                  if (index === 48) { foodname = "Brussel Sprouts " }
                  if (index === 49) { foodname = "Carrot" }
                  if (index === 50) { foodname = "Cassava " }
                  if (index === 51) { foodname = "Cauliflower" }
                  if (index === 52) { foodname = "Celery " }
                  if (index === 53) { foodname = "Chick Pea" }
                  if (index === 54) { foodname = "Cow Pea " }
                  if (index === 55) { foodname = "Cucumber" }
                  if (index === 56) { foodname = "Field Mushroom" }
                  if (index === 57) { foodname = "Garden Pea" }
                  if (index === 58) { foodname = "Green Pepper " }
                  if (index === 59) { foodname = "Green Cabbage" }
                  if (index === 60) { foodname = "Iceberg Lettuce" }
                  if (index === 61) { foodname = "Kale " }
                  if (index === 62) { foodname = "Maize " }
                  if (index === 63) { foodname = "Oyster Mushroom " }
                  if (index === 64) { foodname = "Parsnip" }
                  if (index === 65) { foodname = "Portebello Mushroom " }
                  if (index === 66) { foodname = "Pumpkin " }
                  if (index === 67) { foodname = "Radish " }
                  if (index === 68) { foodname = "Red Cabbage " }
                  if (index === 69) { foodname = "Red Onion " }
                  if (index === 70) { foodname = "Red Pepper" }
                  if (index === 71) { foodname = "Rocket " }
                  if (index === 72) { foodname = "Shiitake Mushroom" }
                  if (index === 73) { foodname = "Spinach" }
                  if (index === 74) { foodname = "Spring Onion" }
                  if (index === 75) { foodname = "Sugar Snap Peas" }
                  if (index === 76) { foodname = "Swedish Turnip / Swede " }
                  if (index === 77) { foodname = "Sweetcorn" }
                  if (index === 78) { foodname = "Sweet Potato" }
                  if (index === 79) { foodname = "Tomato" }
                  if (index === 80) { foodname = "Turnip" }
                  if (index === 81) { foodname = "White Cabbage" }
                  if (index === 82) { foodname = "White Onion" }
                  if (index === 83) { foodname = "White Potato" }
                  if (index === 84) { foodname = "Yellow Pepper " }
                  if (index === 85) { foodname = "Almond" }
                  if (index === 86) { foodname = "Brazil Nut" }
                  if (index === 87) { foodname = "Cashew Nut" }
                  if (index === 88) { foodname = "Chestnut " }
                  if (index === 89) { foodname = "Chia Seeds " }
                  if (index === 90) { foodname = "Cocoa Bean" }
                  if (index === 91) { foodname = "Coconut" }
                  if (index === 92) { foodname = "Flax Seed" }
                  if (index === 93) { foodname = "Green Bean" }
                  if (index === 94) { foodname = "Hazlenut" }
                  if (index === 95) { foodname = "Haricot Bean" }
                  if (index === 96) { foodname = "Kidney Bean" }
                  if (index === 97) { foodname = "Mung Bean" }
                  if (index === 98) { foodname = "Peanut" }
                  if (index === 99) { foodname = "Pistachio" }
                  if (index === 100) { foodname = "Pumpkin Seed" }
                  if (index === 101) { foodname = "Sesame Seed" }
                  if (index === 102) { foodname = "Soybean" }
                  if (index === 103) { foodname = "Sunflower Seed" }
                  if (index === 104) { foodname = "Vanilla Bean" }
                  if (index === 105) { foodname = "Walnut" }
                  if (index === 106) { foodname = "Casein (cow)" }
                  if (index === 107) { foodname = "Cheddar Cheese " }
                  if (index === 108) { foodname = "Cottage Cheese " }
                  if (index === 109) { foodname = "Egg White" }
                  if (index === 110) { foodname = "Egg Yolk" }
                  if (index === 111) { foodname = "Feta Cheese " }
                  if (index === 112) { foodname = "Goat Cheese " }
                  if (index === 113) { foodname = "Goat Milk" }
                  if (index === 114) { foodname = "Mozzarella Cheese " }
                  if (index === 115) { foodname = "Sheep Milk" }
                  if (index === 116) { foodname = "Whey (cow)" }
                  if (index === 117) { foodname = "Whole Egg" }
                  if (index === 118) { foodname = "Whole Milk (cow)" }
                  if (index === 119) { foodname = "Peppercorn" }
                  if (index === 120) { foodname = "Cayenne Pepper " }
                  if (index === 121) { foodname = "Cinnamon" }
                  if (index === 122) { foodname = "Chives " }
                  if (index === 123) { foodname = "Coconut Oil" }
                  if (index === 124) { foodname = "Corn Oil" }
                  if (index === 125) { foodname = "Flaxseed Oil" }
                  if (index === 126) { foodname = "Garlic" }
                  if (index === 127) { foodname = "Ginger" }
                  if (index === 128) { foodname = "Leek" }
                  if (index === 129) { foodname = "Lemon Grass" }
                  if (index === 130) { foodname = "Mint " }
                  if (index === 131) { foodname = "Oregano " }
                  if (index === 132) { foodname = "Olive Oil" }
                  if (index === 133) { foodname = "Paprika" }
                  if (index === 134) { foodname = "Parsley" }
                  if (index === 135) { foodname = "Peanut Oil" }
                  if (index === 136) { foodname = "Sage " }
                  if (index === 137) { foodname = "Sesame Seed Oil" }
                  if (index === 138) { foodname = "Sunflower Oil" }
                  if (index === 139) { foodname = "Turmeric" }
                  if (index === 140) { foodname = "White Pepper " }
                  if (index === 141) { foodname = "Almond Milk" }
                  if (index === 142) { foodname = "Black Tea" }
                  if (index === 143) { foodname = "Cacao" }
                  if (index === 144) { foodname = "Cashew Milk" }
                  if (index === 145) { foodname = "Coconut Milk" }
                  if (index === 146) { foodname = "Coffee" }
                  if (index === 147) { foodname = "Grean Tea" }
                  if (index === 148) { foodname = "Hazlenut Milk" }
                  if (index === 149) { foodname = "Hemp Milk" }
                  if (index === 150) { foodname = "Oat Milk" }
                  if (index === 151) { foodname = "Rice Milk" }
                  if (index === 152) { foodname = "Soya Milk" }
                  if (index === 153) { foodname = "White Tea" }
                  if (index === 154) { foodname = "Beef" }
                  if (index === 155) { foodname = "Chicken" }
                  if (index === 156) { foodname = "Duck" }
                  if (index === 157) { foodname = "Goat " }
                  if (index === 158) { foodname = "Lamb" }
                  if (index === 159) { foodname = "Pork" }
                  if (index === 160) { foodname = "Mutton" }
                  if (index === 161) { foodname = "Turkey" }
                  if (index === 162) { foodname = "Pheasant" }
                  if (index === 163) { foodname = "Veal " }
                  if (index === 164) { foodname = "Venison " }
                  if (index === 165) { foodname = "Anchovy" }
                  if (index === 166) { foodname = "Cod" }
                  if (index === 167) { foodname = "Crab" }
                  if (index === 168) { foodname = "Crayfish" }
                  if (index === 169) { foodname = "Haddock" }
                  if (index === 170) { foodname = "Lobster" }
                  if (index === 171) { foodname = "Mackerel" }
                  if (index === 172) { foodname = "Prawn" }
                  if (index === 173) { foodname = "Salmon" }
                  if (index === 174) { foodname = "Sardine" }
                  if (index === 175) { foodname = "Shrimp" }
                  if (index === 176) { foodname = "Squid" }
                  if (index === 177) { foodname = "Tiger Prawn " }
                  if (index === 178) { foodname = "Trout" }
                  if (index === 179) { foodname = "Tuna" }
                  if (index === 180) { foodname = "Baker's Yeast" }
                  if (index === 181) { foodname = "Barley Flower" }
                  if (index === 182) { foodname = "Balsamic Vinegar" }
                  if (index === 183) { foodname = "Brown Lentils " }
                  if (index === 184) { foodname = "Brewer's Yeast" }
                  if (index === 185) { foodname = "Citric Acid" }
                  if (index === 186) { foodname = "Corn Flour " }
                  if (index === 187) { foodname = "Hemp" }
                  if (index === 188) { foodname = "Hops" }
                  if (index === 189) { foodname = "Green Lentils" }
                  if (index === 190) { foodname = "Horseradish " }
                  if (index === 191) { foodname = "Malt Vinegar" }
                  if (index === 192) { foodname = "Mustard" }
                  if (index === 193) { foodname = "Oat Flour " }
                  if (index === 194) { foodname = "Rice Flour " }
                  if (index === 195) { foodname = "Sugar Cane " }
                  if (index === 196) { foodname = "Vanilla Essence" }
                  if (index === 197) { foodname = "Wasabi " }
                  if (index === 198) { foodname = "Wheatgrass" }
                  if (index === 199) { foodname = "Wheat Flour " }
                  if (index === 200) { foodname = "Beef Protein Isolate " }
                  if (index === 201) { foodname = "Casein Protein Isolate" }
                  if (index === 202) { foodname = "Hemp Protein Isolate" }
                  if (index === 203) { foodname = "Collagen (Bovine) " }
                  if (index === 204) { foodname = "Marine Collagen " }
                  if (index === 205) { foodname = "Pea Protein Isolate" }
                  if (index === 206) { foodname = "Rice Protein Isolate" }
                  if (index === 207) { foodname = "Soy Protein Isolate" }
                  if (index === 208) { foodname = "Spirulina " }
                  if (index === 209) { foodname = "Whey Protein Isolate" }



                  if (item < 0) { value = 0; } else { value = Math.round(item) }

                  if (value >= 10) {
                    valuemorethen10.push({ value: value, foodname: foodname });
                  } else if (value >= 5 && value <= 9) {
                    value5to5.push({ value: value, foodname: foodname });
                  }
                  return (<>

                    {isPdfMode === true && <>

                      {index === 0 && <>  <h3 style={{ width: '100%' }}>Grains & Staples containing Gluten</h3>   </>}

                      {index === 5 && <>  <h3 style={{ width: '100%', marginTop: "15px" }}>Grains & Staples non Gluten</h3>   </>}

                      {index === 10 && <>  <h3 style={{ width: '100%', marginTop: "15px" }}>Fruits</h3>   </>}

                      {index === 43 && <>  <h3 style={{ width: '100%', marginTop: "26px" }}>Vegetables</h3>   </>}

                      {index === 85 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Nuts, Seeds & Beans</h3>   </>}

                      {index === 106 && <>  <h3 style={{ width: '100%', marginTop: "4px" }}>Dairy & Eggs</h3>   </>}

                      {index === 119 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}
                      {index === 119 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}


                      {index === 119 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Herbs, Spices & Oils</h3>   </>}

                      {index === 141 && <>  <h3 style={{ width: '100%', marginTop: "4px" }}>Drinks</h3>   </>}


                      {index === 154 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}
                      {index === 154 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}


                      {index === 154 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Meat & Fish</h3>   </>}


                      {index === 180 && <>  <div style={{ marginBottom: '265px' }}></div> </>}


                      {index === 180 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}
                      {index === 180 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}



                      {index === 180 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Others</h3>   </>}

                      {index === 200 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Supplements</h3>   </>}




                      {index === 22 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}
                      {index === 59 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}

                    </>}




                    {isPdfMode === false && <>

                      {index === 0 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Grains & Staples containing Gluten</h3>   </>}



                      {index === 5 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Grains & Staples non Gluten</h3>   </>}

                      {index === 10 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Fruits</h3>   </>}

                      {index === 43 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Vegetables</h3>   </>}

                      {index === 85 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Nuts, Seeds & Beans</h3>   </>}

                      {index === 106 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Dairy & Eggs</h3>   </>}


                      {index === 119 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Herbs, Spices & Oils</h3>   </>}

                      {index === 141 && <>  <h3 style={{ width: '100%', marginTop: "50x" }}>Drinks</h3>   </>}



                      {index === 154 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Meat & Fish</h3>   </>}





                      {index === 180 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Others</h3>   </>}

                      {index === 200 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Supplements</h3>   </>}



                    </>}







                    <div className={isPdfMode ? "boxxp" : "boxx"}>
                      {value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{value}</div></>}
                      {(value >= 5 && value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{value}</div></>}
                      {value < 5 && <>  <div className='number' style={{ backgroundColor: '#4280b8' }}>{value}</div></>}
                      <div className='name'><h4>{foodname}</h4></div>
                    </div>

                    {isPdfMode === true && <>       {index === 21 && <>  <div style={{ marginBottom: '-20px' }}></div> </>}</>}
                    {isPdfMode === true && <>       {index === 21 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}</>}


                    {isPdfMode === true && <>         {index === 209 && <>  <div style={{ marginBottom: '0px' }}></div> </>}</>}
                    {isPdfMode === true && <>         {index === 209 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}</>}


                    {isPdfMode === true && <>        {index === 58 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}</>}

                    {isPdfMode === true && <>        {index === 84 && <>  <div style={{ marginBottom: '300px' }}></div> </>}</>}

                    {isPdfMode === true && <>         {index === 84 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}</>}

                    {isPdfMode === true && <>    {index === 84 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}</>}


                    {/* {index === 209 && <>  <div style={{marginBottom:'365px'}}></div> </>} */}

                  </>)
                })}





              </div>


            </div>

          </div>



          {/* <img alt='' src='/g2.png' className='uperimg' /> */}
        </div>




        {/* page 4 */}



        {isPdfMode === false && <>




          <div ref={contentRef2} className={isPdfMode ? 'pdfpage4' : 'screen-display'}  >

            {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}
            <img alt='' src='/g1.png' className='uperimg' />
            <div className='pageecontant'>

              <div className='info'>
                <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                {sorting()}

                <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>
                  <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                  {valuemorethen10.map((item2, index2) => {


                    return <>

                      <div className={isPdfMode ? "boxxp" : "boxx"}>
                        {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                        {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                        {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                        <div className='name'><h4>{item2.foodname}</h4></div>
                      </div>

                    </>

                  }
                  )}



                  <p style={{ width: '100%', marginTop: '60px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                  {value5to5.map((item2, index2) => {


                    return <>

                      <div className={isPdfMode ? "boxxp" : "boxx"}>
                        {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                        {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                        {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                        <div className='name'><h4>{item2.foodname}</h4></div>
                      </div>

                    </>

                  }
                  )}







                </div>


              </div>




            </div>
            <img alt='' src='/g2.png' className='uperimg' />
          </div>



        </>}








        {isPdfMode === true && <>



          {/* page 4 */}



          {value5to5.length + valuemorethen10.length <= 32 && <>




            <div ref={contentRef2} className={isPdfMode ? 'pdfpage4' : 'screen-display'}  >

              {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                  {sorting()}

                  <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>
                    <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                    {valuemorethen10.map((item2, index2) => {


                      return <>

                        <div className={isPdfMode ? "boxxp" : "boxx"}>
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}



                    <p style={{ width: '100%', marginTop: '60px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                    {value5to5.map((item2, index2) => {


                      return <>

                        <div className={isPdfMode ? "boxxp" : "boxx"}>
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}







                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>



          </>}



          {/* page 4 */}

          {value5to5.length + valuemorethen10.length > 32 && <>
            <div ref={contentRef2} className={isPdfMode ? 'pdfpage4' : 'screen-display'}  >

              {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                  {sorting()}

                  <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>
                    <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                    {valuemorethen10.map((item2, index2) => {


                      return <>

                        <div className={isPdfMode ? "boxxp" : "boxx"}>
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}











                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>




          </>}




          {/* page 4 */}
          {value5to5.length + valuemorethen10.length > 32 && <>
            <div ref={contentRef2} className={isPdfMode ? 'pdfpage4' : 'screen-display'}  >

              {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  {sorting()}

                  <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>



                    <p style={{ width: '100%', marginTop: '60px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                    {value5to5.map((item2, index2) => {


                      return <>

                        <div className={isPdfMode ? "boxxp" : "boxx"}>
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}







                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>

          </>}



        </>}



      </div>

    </>}

  </>)
}

export default Viewreport